import "./Header.css";
import { Assets } from "../Assets/Assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Profile from "../Profile/Profile";
import { FaUserAlt } from "react-icons/fa";
import { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";

const HeaderWeb = () => {
  const userIdIMg = localStorage.getItem("loginUserPhoto");
  const loginUserName = localStorage.getItem("loginUserName");
  const loginUserPhone = localStorage.getItem("loginUserPhone");
  const loginUserId = localStorage.getItem("loginUserId");
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  const navigate = useNavigate();

  // Update currentPath based on location state when the component mounts or when location changes
  useEffect(() => {
    if (location.state?.from) {
      setCurrentPath("/" + location.state.from);
    } else {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  const handleClick = (path) => {
    setCurrentPath(path);
  };

  const handleUserImgClick = () => {
    const buttonClickNavigatoin =
      loginUserId === "" || loginUserId == null
        ? "/login"
        : document.body.classList.add("modal-active", "overflow-hidden");

    navigate(buttonClickNavigatoin);
  };

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <header className="head-web">
      <div className="web-logo">
        <img src={Assets.Boche_web} alt="Logo" />
      </div>
      <ul className="nav">
        <li>
          <Link
            onClick={() => handleClick("/home")}
            to="/home"
            className={`link ${currentPath === "/home" ? "active" : ""}`}
          >
            Home
          </Link>
        </li>
        {/* <li>
          <Link
            onClick={() => handleClick("/coupen")}
            to="/coupen"
            className={`link ${currentPath === "/coupen" ? "active" : ""}`}
          >
            My Tickets
          </Link>
        </li> */}
        <li>
          <Link
            onClick={() => handleClick("/order")}
            to="/order"
            className={`link ${currentPath === "/order" ? "active" : ""}`}
          >
            My Orders
          </Link>
        </li>
        <li>
          <Link
            onClick={() => handleClick("/store")}
            to="/store"
            className={`link ${currentPath === "/store" ? "active" : ""}`}
          >
            Stores
          </Link>
        </li>
        {/* <li>
          <Link
            onClick={() => handleClick("/transactions")}
            to="/transactions"
            className={`link ${
              currentPath === "/transactions" ? "active" : ""
            }`}
          >
            Transactions
          </Link>
        </li> */}
        <div className="user-web" onClick={handleUserImgClick}>
          {userIdIMg ? (
            <img src={userIdIMg} alt="User" />
          ) : loginUserName ? (
            <div className="user-text">{getInitial(loginUserName)}</div>
          ) : (
            <div className="icon_user_defaul">
              <FaUser />
            </div>
          )}
          <div className="name_number">
            <h6>{loginUserName}</h6>
            <p>{loginUserPhone}</p>
          </div>
        </div>
      </ul>
      <Profile />
    </header>
  );
};

export default HeaderWeb;
