// import React, { useState, useEffect } from "react";
// import "./Transactions.css";
// import { TopNav } from "../../Components/TopNav/TopNav";
// import HeaderWeb from "../../Components/Header/HeaderWeb";
// import Footer from "../Footer/Footer";
// import { query, collection, where, getDocs } from "firebase/firestore";
// import { firestore } from "../../firebase";
// import { useCount } from "../../Context/Context";

// const Transactions = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 870);
//   const [activeTab, setActiveTab] = useState("ALL");
//   const [transactionModelList, setTransactionModelList] = useState([]);
//   const [filterTransactionModelList, setFilterTransactionModelList] = useState(
//     []
//   );

//   const fetchTransactions = async (cusId) => {
//     const q = query(
//       collection(firestore, "TRANSACTIONS"),
//       where("CUSTOMER_ID", "==", cusId)
//     );
//     const snapshot = await getDocs(q);

//     if (!snapshot.empty) {
//       const transactions = snapshot.docs.map((doc) => {
//         const data = doc.data();
//         const transDate = data.TRANSACTION_DATE.toDate();
//         return {
//           customerId: data.CUSTOMER_ID ?? "",
//           transactionId: data.TRANSACTION_ID ?? "",
//           amount: data.AMOUNT ?? 0,
//           customerName: data.CUSTOMER_NAME ?? "",
//           transactionType: data.TRANSACTION_TYPE ?? "",
//           transactionDate: transDate,
//         };
//       });

//       transactions.sort((a, b) => b.transactionDate - a.transactionDate);
//       setTransactionModelList(transactions);
//       setFilterTransactionModelList(transactions);
//     }
//   };

//   useEffect(() => {
//     const cusId = localStorage.getItem("loginUserId");
//     fetchTransactions(cusId);
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 870);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const renderContent = () => {
//     const filteredTransactions = transactionModelList.filter((transaction) => {
//       switch (activeTab) {
//         case "ALL":
//           return true;
//         case "WINNING":
//           return transaction.transactionType === "WINNING";
//         case "TOP UP":
//           return transaction.transactionType === "DEPOSIT";
//         case "WITHDRAW":
//           return transaction.transactionType === "WITHDRAW";
//         case "PURCHASE":
//           return transaction.transactionType === "PURCHASE";
//         default:
//           return false;
//       }
//     });

//     return filteredTransactions.length > 0 ? (
//       filteredTransactions.map((transaction, index) => (
//         <div className="single-item" key={index}>
//           <div className="date-time">
//             <p className={transaction.transactionType.toLowerCase()}>
//               {transaction.transactionType == "DEPOSIT"
//                 ? "TOP UP"
//                 : transaction.transactionType}{" "}
//               Amount
//             </p>
//             <p>
//               {transaction.transactionDate.toLocaleTimeString()}{" "}
//               <span>{transaction.transactionDate.toLocaleDateString()}</span>
//             </p>
//           </div>
//           <div
//             className={
//               transaction.transactionType === "DEPOSIT" ||
//               transaction.transactionType === "WINNING"
//                 ? "cash-details"
//                 : "cash-draw"
//             }
//           >
//             <h4>
//               {transaction.transactionType === "DEPOSIT" ||
//               transaction.transactionType === "WINNING"
//                 ? `+ ₹${transaction.amount}`
//                 : `- ₹${transaction.amount}`}
//             </h4>
//           </div>
//         </div>
//       ))
//     ) : (
//       <div>No {activeTab} Transactions</div>
//     );
//   };

//   return (
//     <>
//       {isMobile ? <TopNav title="Transactions" /> : <HeaderWeb />}
//       <div className="web-transation">
//         <ul className="list-tra">
//           {["ALL", "WINNING", "TOP UP", "WITHDRAW", "PURCHASE"].map((tab) => (
//             <li
//               key={tab}
//               className={activeTab === tab ? "active" : ""}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </li>
//           ))}
//         </ul>
//         <div className="list-amount">{renderContent()}</div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Transactions;

// import React, { useState, useEffect, useRef } from "react";
// import "./Transactions.css";
// import { TopNav } from "../../Components/TopNav/TopNav";
// import HeaderWeb from "../../Components/Header/HeaderWeb";
// import Footer from "../Footer/Footer";
// import { query, collection, where, getDocs, orderBy, limit, startAfter } from "firebase/firestore";
// import { firestore } from "../../firebase";
// import { useCount } from "../../Context/Context";
// import { ClipLoader } from "react-spinners";

// const Transactions = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 870);
//   const [activeTab, setActiveTab] = useState("ALL");
//   const [transactionModelList, setTransactionModelList] = useState([]);
//   const [filterTransactionModelList, setFilterTransactionModelList] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [lastVisible, setLastVisible] = useState(null);
//   const [hasMore, setHasMore] = useState(true);
//   const loadMoreRef = useRef(null);

//   const fetchTransactions = async (cusId, startAfterDoc) => {
//     try {
//       setLoading(true);
//       const limitSize = 10; // Adjust this limit size as per your requirement

//       let q = collection(firestore, "TRANSACTIONS");
//       q = where("CUSTOMER_ID", "==", cusId);

//       switch (activeTab) {
//         case "WINNING":
//           q = query(q, where("TRANSACTION_TYPE", "==", "WINNING"));
//           break;
//         case "TOP UP":
//           q = query(q, where("TRANSACTION_TYPE", "==", "DEPOSIT"));
//           break;
//         case "WITHDRAW":
//           q = query(q, where("TRANSACTION_TYPE", "==", "WITHDRAW"));
//           break;
//         case "PURCHASE":
//           q = query(q, where("TRANSACTION_TYPE", "==", "PURCHASE"));
//           break;
//         default:
//           break;
//       }

//       // if (startAfterDoc) {
//       //   q = query(q, orderBy("TRANSACTION_DATE"), startAfter(startAfterDoc), limit(limitSize));
//       // } else {
//       //   q = query(q, orderBy("TRANSACTION_DATE"), limit(limitSize));
//       // }

//       const querySnapshot = await getDocs(q);
//       if (querySnapshot.empty) {
//         setHasMore(false);
//         setLoading(false);
//         return;
//       }

//       const transactions = querySnapshot.docs.map((doc) => {
//         const data = doc.data();
//         const transDate = data.TICKET_DATE.toDate(); // Ensure this matches your Firestore field name
//         return {
//           customerId: data.CUSTOMER_ID ?? "",
//           transactionId: data.TRANSACTION_ID ?? "",
//           amount: data.AMOUNT ?? 0,
//           customerName: data.CUSTOMER_NAME ?? "",
//           transactionType: data.TRANSACTION_TYPE ?? "",
//           transactionDate: transDate,
//         };
//       });

//       setTransactionModelList((prevTransactions) =>
//         startAfterDoc ? [...prevTransactions, ...transactions] : transactions
//       );
//       setFilterTransactionModelList((prevFilteredTransactions) =>
//         startAfterDoc ? [...prevFilteredTransactions, ...transactions] : transactions
//       );
//       setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching transactions:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     const cusId = localStorage.getItem("loginUserId");
//     if (cusId) {
//       fetchTransactions(cusId, null);
//     }

//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 870);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         if (entries[0].isIntersecting && hasMore && !loading) {
//           fetchTransactions(localStorage.getItem("loginUserId"), lastVisible);
//         }
//       },
//       {
//         root: null,
//         rootMargin: "20px",
//         threshold: 0.1,
//       }
//     );

//     if (loadMoreRef.current) {
//       observer.observe(loadMoreRef.current);
//     }

//     return () => {
//       if (loadMoreRef.current) {
//         observer.unobserve(loadMoreRef.current);
//       }
//     };
//   }, [lastVisible, hasMore, loading, activeTab]);

//   const renderContent = () => {
//     if (loading && !transactionModelList.length) {
//       return (
//         <div className="loader-order" style={{ textAlign: "center" }}>
//           <ClipLoader color="#36d7b7" size={50} />
//         </div>
//       );
//     }

//     return filterTransactionModelList.length > 0 ? (
//       filterTransactionModelList.map((transaction, index) => (
//         <div className="single-item" key={index}>
//           <div className="date-time">
//             <p className={transaction.transactionType.toLowerCase()}>
//               {transaction.transactionType === "DEPOSIT"
//                 ? "TOP UP"
//                 : transaction.transactionType}{" "}
//               Amount
//             </p>
//             <p>
//               {transaction.transactionDate.toLocaleTimeString()}{" "}
//               <span>{transaction.transactionDate.toLocaleDateString()}</span>
//             </p>
//           </div>
//           <div
//             className={
//               transaction.transactionType === "DEPOSIT" ||
//               transaction.transactionType === "WINNING"
//                 ? "cash-details"
//                 : "cash-draw"
//             }
//           >
//             <h4>
//               {transaction.transactionType === "DEPOSIT" ||
//               transaction.transactionType === "WINNING"
//                 ? `+ ₹${transaction.amount}`
//                 : `- ₹${transaction.amount}`}
//             </h4>
//           </div>
//         </div>
//       ))
//     ) : (
//       <div>No {activeTab} Transactions</div>
//     );
//   };

//   console.log("localStorage.getItem('loginUserId'):", localStorage.getItem("loginUserId"));
//   console.log("transactionModelList:", transactionModelList);
//   console.log("filterTransactionModelList:", filterTransactionModelList);
//   console.log("loading:", loading);

//   return (
//     <>
//       {isMobile ? <TopNav title="Transactions" /> : <HeaderWeb />}
//       <div className="web-transation">
//         <ul className="list-tra">
//           {["ALL", "WINNING", "TOP UP", "WITHDRAW", "PURCHASE"].map((tab) => (
//             <li
//               key={tab}
//               className={activeTab === tab ? "active" : ""}
//               onClick={() => {
//                 setActiveTab(tab);
//                 setTransactionModelList([]);
//                 setFilterTransactionModelList([]);
//                 setLastVisible(null);
//                 setHasMore(true);
//                 fetchTransactions(localStorage.getItem("loginUserId"), null);
//               }}
//             >
//               {tab}
//             </li>
//           ))}
//         </ul>
//         <div className="list-amount">{renderContent()}</div>
//         {loading && (
//           <div className="loader-order" style={{ textAlign: "center" }}>
//             <ClipLoader color="#36d7b7" size={50} />
//           </div>
//         )}
//         <div ref={loadMoreRef} />
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Transactions;

// import React, { useState, useEffect } from "react";
// import "./Transactions.css";
// import { TopNav } from "../../Components/TopNav/TopNav";
// import HeaderWeb from "../../Components/Header/HeaderWeb";
// import Footer from "../Footer/Footer";
// import { query, collection, where, getDocs } from "firebase/firestore";
// import { firestore } from "../../firebase";
// import { useCount } from "../../Context/Context";

// const Transactions = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 870);
//   const [activeTab, setActiveTab] = useState("ALL");
//   const [transactionModelList, setTransactionModelList] = useState([]);
//   const [filterTransactionModelList, setFilterTransactionModelList] = useState([]);
//   const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items initially loaded
//   const [loadedTransactions, setLoadedTransactions] = useState(itemsPerPage); // Track how many transactions are loaded
//   const loadMoreThreshold = 5; // Number of items to load each time user scrolls

//   const fetchTransactions = async (cusId) => {
//     const q = query(
//       collection(firestore, "TRANSACTIONS"),
//       where("CUSTOMER_ID", "==", cusId)
//     );
//     const snapshot = await getDocs(q);

//     if (!snapshot.empty) {
//       const transactions = snapshot.docs.map((doc) => {
//         const data = doc.data();
//         const transDate = data.TRANSACTION_DATE.toDate();
//         return {
//           customerId: data.CUSTOMER_ID ?? "",
//           transactionId: data.TRANSACTION_ID ?? "",
//           amount: data.AMOUNT ?? 0,
//           customerName: data.CUSTOMER_NAME ?? "",
//           transactionType: data.TRANSACTION_TYPE ?? "",
//           transactionDate: transDate,
//         };
//       });

//       transactions.sort((a, b) => b.transactionDate - a.transactionDate);
//       setTransactionModelList(transactions);
//       setFilterTransactionModelList(transactions);
//       setLoadedTransactions(itemsPerPage); // Reset loaded transactions
//     }
//   };

//   useEffect(() => {
//     const cusId = localStorage.getItem("loginUserId");
//     fetchTransactions(cusId);
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 870);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         window.innerHeight + document.documentElement.scrollTop ===
//         document.documentElement.offsetHeight
//       ) {
//         setLoadedTransactions((prev) => prev + loadMoreThreshold);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [loadedTransactions]); // Add loadedTransactions to dependency array

//   const renderContent = () => {
//     const filteredTransactions = filterTransactionModelList.filter((transaction) => {
//       switch (activeTab) {
//         case "ALL":
//           return true;
//         case "WINNING":
//           return transaction.transactionType === "WINNING";
//         case "TOP UP":
//           return transaction.transactionType === "DEPOSIT";
//         case "WITHDRAW":
//           return transaction.transactionType === "WITHDRAW";
//         case "PURCHASE":
//           return transaction.transactionType === "PURCHASE";
//         default:
//           return false;
//       }
//     });

//     const visibleTransactions = filteredTransactions.slice(0, loadedTransactions);

//     return visibleTransactions.length > 0 ? (
//       visibleTransactions.map((transaction, index) => (
//         <div className="single-item" key={index}>
//           <div className="date-time">
//             <p className={transaction.transactionType.toLowerCase()}>
//               {transaction.transactionType === "DEPOSIT"
//                 ? "TOP UP"
//                 : transaction.transactionType}{" "}
//               Amount
//             </p>
//             <p>
//               {transaction.transactionDate.toLocaleTimeString()}{" "}
//               <span>{transaction.transactionDate.toLocaleDateString()}</span>
//             </p>
//           </div>
//           <div
//             className={
//               transaction.transactionType === "DEPOSIT" ||
//               transaction.transactionType === "WINNING"
//                 ? "cash-details"
//                 : "cash-draw"
//             }
//           >
//             <h4>
//               {transaction.transactionType === "DEPOSIT" ||
//               transaction.transactionType === "WINNING"
//                 ? `+ ₹${transaction.amount}`
//                 : `- ₹${transaction.amount}`}
//             </h4>
//           </div>
//         </div>
//       ))
//     ) : (
//       <div>No {activeTab} Transactions</div>
//     );
//   };

//   return (
//     <>
//       {isMobile ? <TopNav title="Transactions" /> : <HeaderWeb />}
//       <div className="web-transation">
//         <ul className="list-tra">
//           {["ALL", "WINNING", "TOP UP", "WITHDRAW", "PURCHASE"].map((tab) => (
//             <li
//               key={tab}
//               className={activeTab === tab ? "active" : ""}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </li>
//           ))}
//         </ul>
//         <div className="list-amount">{renderContent()}</div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Transactions;

// import React, { useState, useEffect } from "react";
// import "./Transactions.css";
// import { TopNav } from "../../Components/TopNav/TopNav";
// import HeaderWeb from "../../Components/Header/HeaderWeb";
// import Footer from "../Footer/Footer";
// import { query, collection, where, getDocs } from "firebase/firestore";
// import { firestore } from "../../firebase";
// import { ClipLoader } from "react-spinners";
// import { useCount } from "../../Context/Context";

// const Transactions = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 870);
//   const [activeTab, setActiveTab] = useState("ALL");
//   const [transactionModelList, setTransactionModelList] = useState([]);
//   const [filterTransactionModelList, setFilterTransactionModelList] = useState([]);
//   const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items initially loaded
//   const [loadedTransactions, setLoadedTransactions] = useState(itemsPerPage); // Track how many transactions are loaded
//   const [isLoading, setIsLoading] = useState(false); // Loading state for fetching more transactions
//   const loadMoreThreshold = 10; // Number of items to load each time user scrolls

//   const fetchTransactions = async (cusId) => {
//     setIsLoading(true);
//     const q = query(
//       collection(firestore, "TRANSACTIONS"),
//       where("CUSTOMER_ID", "==", cusId)
//     );
//     const snapshot = await getDocs(q);

//     if (!snapshot.empty) {
//       const transactions = snapshot.docs.map((doc) => {
//         const data = doc.data();
//         const transDate = data.TRANSACTION_DATE.toDate();
//         return {
//           customerId: data.CUSTOMER_ID ?? "",
//           transactionId: data.TRANSACTION_ID ?? "",
//           amount: data.AMOUNT ?? 0,
//           customerName: data.CUSTOMER_NAME ?? "",
//           transactionType: data.TRANSACTION_TYPE ?? "",
//           transactionDate: transDate,
//         };
//       });

//       transactions.sort((a, b) => b.transactionDate - a.transactionDate);
//       setTransactionModelList(transactions);
//       setFilterTransactionModelList(transactions);
//       setLoadedTransactions(itemsPerPage); // Reset loaded transactions
//     }
//     setIsLoading(false);
//   };

//   useEffect(() => {
//     const cusId = localStorage.getItem("loginUserId");
//     fetchTransactions(cusId);
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 870);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (
//         window.innerHeight + document.documentElement.scrollTop ===
//         document.documentElement.offsetHeight
//       ) {
//         setLoadedTransactions((prev) => prev + loadMoreThreshold);
//       }

//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [loadedTransactions]); // Add loadedTransactions to dependency array

//   const renderContent = () => {
//     const filteredTransactions = filterTransactionModelList.filter((transaction) => {
//       switch (activeTab) {
//         case "ALL":
//           return true;
//         case "WINNING":
//           return transaction.transactionType === "WINNING";
//         case "TOP UP":
//           return transaction.transactionType === "DEPOSIT";
//         case "WITHDRAW":
//           return transaction.transactionType === "WITHDRAW";
//         case "PURCHASE":
//           return transaction.transactionType === "PURCHASE";
//         default:
//           return false;
//       }
//     });

//     const visibleTransactions = filteredTransactions.slice(0, loadedTransactions);

//     return visibleTransactions.length > 0 ? (
//       visibleTransactions.map((transaction, index) => (
//         <div className="single-item" key={index}>
//           <div className="date-time">
//             <p className={transaction.transactionType.toLowerCase()}>
//               {transaction.transactionType === "DEPOSIT"
//                 ? "TOP UP"
//                 : transaction.transactionType}{" "}
//               Amount
//             </p>
//             <p>
//               {transaction.transactionDate.toLocaleTimeString()}{" "}
//               <span>{transaction.transactionDate.toLocaleDateString()}</span>
//             </p>
//           </div>
//           <div
//             className={
//               transaction.transactionType === "DEPOSIT" ||
//               transaction.transactionType === "WINNING"
//                 ? "cash-details"
//                 : "cash-draw"
//             }
//           >
//             <h4>
//               {transaction.transactionType === "DEPOSIT" ||
//               transaction.transactionType === "WINNING"
//                 ? `+ ₹${transaction.amount}`
//                 : `- ₹${transaction.amount}`}
//             </h4>
//           </div>
//         </div>
//       ))
//     ) : (
//       <div>No {activeTab} Transactions</div>
//     );
//   };

//   return (
//     <>
//       {isMobile ? <TopNav title="Transactions" /> : <HeaderWeb />}
//       <div className="web-transation">
//         <ul className="list-tra">
//           {["ALL", "WINNING", "TOP UP", "WITHDRAW", "PURCHASE"].map((tab) => (
//             <li
//               key={tab}
//               className={activeTab === tab ? "active" : ""}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </li>
//           ))}
//         </ul>
//         <div className="list-amount">{renderContent()}</div>
//         {isLoading && (
//           <div className="loader">
//             <ClipLoader color="#36d7b7" size={35} />
//           </div>
//         )}
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Transactions;

import React, { useState, useEffect } from "react";
import "./Transactions.css";
import HeaderWeb from "../../Components/Header/HeaderWeb";
import Footer from "../Footer/Footer";
import { query, collection, where, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { ClipLoader } from "react-spinners";
import transactionIMg from "../../Components/Assets/Images/transation_empty.png";
import TopHome from "../../Components/TopNav/TopHome";

const Transactions = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 870);
  const [activeTab, setActiveTab] = useState("ALL");
  const [transactionModelList, setTransactionModelList] = useState([]);
  const [loadedTransactions, setLoadedTransactions] = useState(5); // Number of transactions to load initially
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const fetchTransactions = async (cusId) => {
    setIsLoading(true);
    const q = query(
      collection(firestore, "TRANSACTIONS"),
      where("CUSTOMER_ID", "==", cusId)
    );
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      const transactions = snapshot.docs.map((doc) => {
        const data = doc.data();
        const transDate = data.TRANSACTION_DATE?.toDate();
        return {
          customerId: data.CUSTOMER_ID ?? "",
          transactionId: data.TRANSACTION_ID ?? "",
          amount: data.AMOUNT ?? 0,
          customerName: data.CUSTOMER_NAME ?? "",
          transactionType: data.TRANSACTION_TYPE ?? "",
          transactionDate: transDate,
        };
      });

      console.log(transactions, "transaction .....................");

      transactions.sort((a, b) => b.transactionDate - a.transactionDate);
      setTransactionModelList(transactions);
      setLoadedTransactions(5); // Reset loaded transactions
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const cusId = localStorage.getItem("loginUserId");
    fetchTransactions(cusId);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 870);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 100 &&
        !isLoading
      ) {
        setIsLoading(true); // Start loader
        setLoadedTransactions((prev) => prev + 10);
        setIsLoading(false); // Stop loader
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (activeTab !== "ALL") {
      setLoadedTransactions(10); // Reset to initial load count when tab changes
    }
  }, [activeTab]);

  const renderContent = () => {
    const filteredTransactions = transactionModelList.filter((transaction) => {
      switch (activeTab) {
        case "ALL":
          return true;
        case "WINNING":
          return transaction.transactionType === "WINNING";
        case "TOP UP":
          return transaction.transactionType === "DEPOSIT";
        case "PURCHASE":
          return transaction.transactionType === "PURCHASE";
        case "WITHDRAW":
          return (
            transaction.transactionType === "WITHDRAW" ||
            transaction.transactionType === "WITHDRAW_REQUEST"
          );

        default:
          return false;
      }
    });

    if (filteredTransactions.length === 0) {
      return (
        <div className="empty_container_transaction">
          {" "}
          <div className="image_container">
            <img style={{ textAlign: "center" }} src={transactionIMg} alt="" />
          </div>
          <div className="empty_text">
            <p>No Transactions Found!</p>
            <span>
              Its looks like you haven't made any transactions yet. When you
              place an order, it will appear here.
            </span>
          </div>
        </div>
      );
    }

    const visibleTransactions = filteredTransactions.slice(
      0,
      loadedTransactions
    );

    return visibleTransactions.length > 0 ? (
      visibleTransactions.map((transaction, index) => (
        <div className="single-item" key={index}>
          <div className="date-time">
            <p className={transaction.transactionType.toLowerCase()}>
              {transaction.transactionType === "DEPOSIT"
                ? "TOP UP"
                : transaction.transactionType}{" "}
              Amount
            </p>
            <p>
              {transaction.transactionDate?.toLocaleTimeString()}{" "}
              <span>{transaction.transactionDate?.toLocaleDateString()}</span>
            </p>
          </div>
          <div
            className={
              transaction.transactionType === "DEPOSIT" ||
              transaction.transactionType === "WINNING"
                ? "cash-details"
                : "cash-draw"
            }
          >
            <h4>
              {transaction.transactionType === "DEPOSIT" ||
              transaction.transactionType === "WINNING"
                ? `+ ₹${transaction.amount?.toFixed(2)}`
                : `- ₹${transaction.amount?.toFixed(2)}`}
            </h4>
          </div>
        </div>
      ))
    ) : (
      <div>No {activeTab} Transactions</div>
    );
  };

  return (
    <>
      {isMobile ? <TopHome head="Transactions" /> : <HeaderWeb />}
      <div className="web-transation">
        <ul className="list-tra">
          {/* {["ALL", "TOP UP", "WITHDRAW", "PURCHASE"].map((tab) => ( */}
          {["ALL", "WINNING", "TOP UP", "WITHDRAW", "PURCHASE"].map((tab) => (
            <li
              key={tab}
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </li>
          ))}
        </ul>

        <div className="item_wrapper">
          {transactionModelList.length === 0 ? (
            <div className="empty_container_transaction">
              {" "}
              <div className="image_container">
                <img
                  style={{ textAlign: "center" }}
                  src={transactionIMg}
                  alt=""
                />
              </div>
              <div className="empty_text">
                <p>No Transactions Found!</p>
                <span>
                  Its looks like you haven't made any transactions yet. When you
                  place an order, it will appear here.
                </span>
              </div>
            </div>
          ) : (
            <>
              <div className="list-amount">{renderContent()}</div>
              {isLoading && (
                <div className="loader" style={{ textAlign: "center" }}>
                  <ClipLoader color="#36d7b7" size={35} />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Transactions;
