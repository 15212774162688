import React, { useEffect, useState } from "react";
import "./DeliverySummery.css";
import { Assets } from "../Assets/Assets";
import { FiTruck } from "react-icons/fi";
import { useCount } from "../../Context/Context";

const DeliverySummery = ({ deliveryRate }) => {
  const { count, totalPrice, increment, decrement, showCount, setShowCount } =
    useCount();

  const [grandTotal, setGrandTotal] = useState(totalPrice);

  useEffect(() => {
    // setShowCount(false);
    if (deliveryRate) {
      setGrandTotal(totalPrice + deliveryRate);
    } else {
      setGrandTotal(totalPrice);
    }
  }, [totalPrice, deliveryRate]);

  return (
    <div className="delivery-summery">
      <div className="delivery-summery-box">
        <div className="delivery-summery-top">
          <div className="tea-img-tea">
            <img src={Assets.Tea} alt="" />
          </div>
          <div className="delivery-tea-details">
            <p>boCHE TEA</p>
            <p>100gm</p>
            <h6>₹40.00</h6>
            {/* <p>
              <FiTruck />
              <span>Expected Delivery </span>
            </p> */}
            {/* <h6>30th May 2024</h6> */}
            {/* <p>Edit Quantity</p> */}
          </div>
        </div>
        <div className="edit-count">
          {showCount ? (
            <>
              <div className="edit-dec" onClick={decrement}>
                -
              </div>
              <div className="edit-num">{count}</div>
              <div className="edit-inc" onClick={increment}>
                +
              </div>
            </>
          ) : (
            <div className="quantity_div">
              <p>Quantity: </p>
              {count}
            </div>
          )}
        </div>
      </div>

      <div className="delivery-amount-summery">
        <div className="delivery-total">
          <p>Total </p>
          <p>₹{totalPrice}</p>
        </div>
        {deliveryRate ? (
          <div className="deliver-charges">
            <p>Delivery Charge</p>
            <p>₹{deliveryRate ? deliveryRate : 0}</p>
          </div>
        ) : (
          ""
        )}
        <div className="delivery-total-amount">
          <h6>Total Amount</h6>
          <h6>₹{grandTotal}</h6>
        </div>
      </div>
    </div>
  );
};

export default DeliverySummery;
