import React, { useEffect, useState } from "react";
import "./Count.css";
import { Assets } from "../Assets/Assets";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useCount } from "../../Context/Context";
import { useNavigate } from "react-router-dom";

export const Count = () => {
  const productPrice = localStorage.getItem("productPrice");
  const productImages = localStorage.getItem("productImages");
  const loginUserId = localStorage.getItem("loginUserId");
  const [clickedButton, setClickedButton] = useState(null);

  const { count, totalPrice, increment, decrement } = useCount();
  const [isMobile, setIsMobile] = useState(window.innerWidth > 480);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 480);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleButtonClick = () => {
    const buttonLink =
      loginUserId === "" || loginUserId === null ? "/login" : "/paymentweb";
    navigate(buttonLink, {
      state: { count, totalPrice, productPrice, productImages },
    });
  };
  const handleClick = (action) => {
    setClickedButton(action);
    setTimeout(() => {
      setClickedButton(null);
    }, 200);
    action === "increment" ? increment() : decrement();
  };

  return (
    <div className="count">
      <div className="tea-number">
        <img src={Assets.Line} alt="" className="round" />
        <img src={Assets.Tea} alt="" className="tea" />
      </div>
      <div className="count-box">
        <div className="web-content">
          <h6>
            boCHE TEA <span>100gm</span>
          </h6>
          <p>
            Experience the exceptional taste of Bochetea, handpicked from
            Wayanad's Boche 1000 Acres Tea Plantation. Our tea embodies the
            region's splendor with its rich aroma and taste. Enjoy the premium
            quality of Bochetea, a blend of tradition and excellence.
          </p>
          <h6>INR40.00</h6>
        </div>
        <div className="count-web">
          <h2>₹ {totalPrice}</h2>
          <h6>
            <span>{count}</span> PACKETS X ₹{productPrice}
          </h6>
        </div>
        <div className="mobile-view">
          <div className="couter">
            <div
              onClick={() => handleClick("decrement")}
              className={clickedButton === "decrement" ? "clicked" : ""}
            >
              <FaMinus />
            </div>
            <p>{count}</p>
            <div
              onClick={() => handleClick("increment")}
              className={clickedButton === "increment" ? "clicked" : ""}
            >
              <FaPlus />
            </div>
          </div>
        </div>
        <div className="web-counter">
          <div className="couter-web">
            <div
              onClick={() => handleClick("decrement")}
              className={`inc ${
                clickedButton === "decrement" ? "clicked" : ""
              }`}
            >
              <FaMinus />
            </div>
            <p>{count}</p>
            <div
              onClick={() => handleClick("increment")}
              className={`dec ${
                clickedButton === "increment" ? "clicked" : ""
              }`}
            >
              <FaPlus />
            </div>
          </div>
        </div>
        {/* {isMobile && (
          <button className="bbttn" onClick={handleButtonClick}>
            Buy Now
          </button>
        )} */}
      </div>
    </div>
  );
};

// import React, { useEffect, useState } from "react";
// import "./Count.css";
// import { Assets } from "../Assets/Assets";
// import { FaMinus, FaPlus } from "react-icons/fa";
// import { useCount } from "../../Context/Context";
// import { useNavigate } from "react-router-dom";
// import Tour from 'reactour'; // Correct import statement for reactour

// export const Count = () => {
//   const productPrice = localStorage.getItem("productPrice");
//   const productImages = localStorage.getItem("productImages");
//   const loginUserId = localStorage.getItem("loginUserId");
//   const [clickedButton, setClickedButton] = useState(null);
//   const [isTourOpen, setIsTourOpen] = useState(true); // State to control tour visibility

//   const { count, totalPrice, increment, decrement } = useCount();
//   const [isMobile, setIsMobile] = useState(window.innerWidth > 480);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth > 480);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleButtonClick = () => {
//     const buttonLink =
//       loginUserId === "" || loginUserId === null ? "/login" : "/paymentweb";
//     navigate(buttonLink, {
//       state: { count, totalPrice, productPrice, productImages },
//     });
//   };

//   const handleClick = (action) => {
//     setClickedButton(action);
//     setTimeout(() => {
//       setClickedButton(null);
//     }, 200);
//     action === "increment" ? increment() : decrement();
//   };

//   const tourSteps = [
//     {
//       selector: '.bbttn', // Target the 'Buy Now' button
//       content: (
//         <>
//           <h3>Welcome!</h3>
//           <p>Click the "Buy Now" button to proceed with your purchase.</p>
//         </>
//       ),
//       position: 'top', // Position the tooltip above the button
//     },
//     {
//       selector: '.couter-web .dec', // Target the decrement button
//       content: (
//         <>
//           <h3>Adjust Quantity</h3>
//           <p>Click here to decrease the number of items.</p>
//         </>
//       ),
//       position: 'left', // Position the tooltip to the left of the button
//     },
//     {
//       selector: '.couter-web .inc', // Target the increment button
//       content: (
//         <>
//           <h3>Adjust Quantity</h3>
//           <p>Click here to increase the number of items.</p>
//         </>
//       ),
//       position: 'right', // Position the tooltip to the right of the button
//     },
//   ];

//   useEffect(() => {
//     if (isTourOpen) {
//       document.body.classList.add("no-scroll");
//     } else {
//       document.body.classList.remove("no-scroll");
//     }

//     // Cleanup when the component unmounts
//     return () => {
//       document.body.classList.remove("no-scroll");
//     };
//   }, [isTourOpen]);

//   return (
//     <div className="count">
//       <Tour
//         steps={tourSteps}
//         isOpen={isTourOpen}
//         onRequestClose={() => setIsTourOpen(false)}
//       />
//       <div className="tea-number">
//         <img src={Assets.Line} alt="" className="round" />
//         <img src={Assets.Tea} alt="" className="tea" />
//       </div>
//       <div className="count-box">
//         <div className="web-content">
//           <h6>
//             boCHE TEA <span>100gm</span>
//           </h6>
//           <p>
//             Experience the exceptional taste of Bochetea, handpicked from Wayanad's
//             Boche 1000 Acres Tea Plantation. Our tea embodies the region's splendor
//             with its rich aroma and taste. Enjoy the premium quality of Bochetea, a
//             blend of tradition and excellence.
//           </p>
//           <h6>INR40.00</h6>
//         </div>
//         <div className="count-web">
//           <h2>₹ {totalPrice}</h2>
//           <h6>
//             <span>{count}</span>
//           </h6>
//         </div>
//         <div className="mobile-view">
//           <div className="couter">
//             <div
//               onClick={() => handleClick("decrement")}
//               className={clickedButton === "decrement" ? "clicked" : ""}
//             >
//               <FaMinus />
//             </div>
//             <p>{count}</p>
//             <div
//               onClick={() => handleClick("increment")}
//               className={clickedButton === "increment" ? "clicked" : ""}
//             >
//               <FaPlus />
//             </div>
//           </div>
//         </div>
//         <div className="web-counter">
//           <div className="couter-web">
//             <div
//               onClick={() => handleClick("decrement")}
//               className={`inc ${
//                 clickedButton === "decrement" ? "clicked" : ""
//               }`}
//             >
//               <FaMinus />
//             </div>
//             <p>{count}</p>
//             <div
//               onClick={() => handleClick("increment")}
//               className={`dec ${
//                 clickedButton === "increment" ? "clicked" : ""
//               }`}
//             >
//               <FaPlus />
//             </div>
//           </div>
//         </div>
//         {isMobile && (
//           <button className="bbttn" onClick={handleButtonClick}>
//             Buy Now
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };
