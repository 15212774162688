import React, { useEffect, useState } from "react";
import "./WithdrawDetails.css";
import AccountDetails from "../../Components/AccoutDetails/AccoutDetails";
import { Button } from "../Button/Button";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useCount } from "../../Context/Context";

const WithdrawDetails = () => {
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [amount, setAmount] = useState();
  const cusId = localStorage.getItem("loginUserId");
  const cusName = localStorage.getItem("loginUserName");
  const cusPhone = localStorage.getItem("loginUserPhone");

  const [holderName, setHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifceCode, setIfceCode] = useState("");

  const [hasBankData, setHasBankData] = useState(true);

  const handleRedeemCLick = () => {
    if (!hasBankData) {
      alert("Choose your Bank");
    } else {
      if (currentWinningBalance >= amount) {
        requestForWithdrawFun(
          cusId,
          amount,
          bankName,
          holderName,
          accountNumber,
          ifceCode
        );
        alert("Success!");
        console.log("Success to redeem");
      } else {
        alert("No Sufficient Balance");
      }
    }
  };

  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate(-1);
  };

  const hasData = accountNumber.length > 0;

  const handleButtonClick = () => {
    setShowNewAddressForm(true);
  };

  const priceHandler = (amount) => {
    setAmount(amount);
  };

  const price = [
    { id: 1, price: 50 },
    { id: 2, price: 100 },
    { id: 3, price: 200 },
    { id: 5, price: 300 },
    { id: 6, price: 400 },
    { id: 9, price: 500 },
  ];

  const fetchData = async () => {
    try {
      const ref = doc(firestore, "CUSTOMERS", cusId);
      const docSnap = await getDoc(ref);

      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log("Document data:", data); // Logging the fetched data
        setBankName(data.BANK_NAME || "");
        setHolderName(data.ACCOUNT_HOLDER_NAME || "");
        setAccountNumber(data.ACCOUNT_NUMBER || "");
        setIfceCode(data.IFSCE || "");
        setShowNewAddressForm(false); // Hide form after fetching data
        setHasBankData(true);
      } else {
        console.log("No such document!");
        setHasBankData(false);
      }
    } catch (error) {
      console.log("Error fetching document: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [cusId]);

  const [walletTransactionList, setWalletTransactionList] = useState([]);
  const { currentWinningBalance, setCurrentWinningBalance } = useCount();

  const requestForWithdrawFun = (
    userId,
    amount,
    bankName,
    holderName,
    accountNumber,
    ifceCode
  ) => {
    const now = new Date();
    const nowEpoch = now.getTime();

    const walletMap = {
      TYPE: "WITHDRAW_REQUEST",
      AMOUNT: amount,
      DATE: now,
      CUSTOMER_ID: userId,
    };

    const mapTransaction = {
      AMOUNT: amount,
      TRANSACTION_TYPE: "WITHDRAW_REQUEST",
      DATE: now,
      CUSTOMER_ID: userId,
    };

    const mapWithdraw = {
      AMOUNT: amount,
      TRANSACTION_TYPE: "WITHDRAW_REQUEST",
      DATE: now,
      CUSTOMER_ID: userId,
      TRANSACTION_ID: nowEpoch.toString(),
      STATUS: "PENDING",
      BANK_NAME: bankName,
      ACCOUNT_HOLDER_NAME: holderName,
      ACCOUNT_NUMBER: accountNumber,
      IFSC: ifceCode,
      CUSTOMER_NAME: cusName,
      CUSTOMER_PHONE: cusPhone,
    };

    setDoc(
      doc(firestore, "WALLET_TRANSACTION", nowEpoch.toString()),
      walletMap,
      { merge: true }
    );
    setDoc(
      doc(firestore, "TRANSACTIONS", nowEpoch.toString()),
      mapTransaction,
      { merge: true }
    );
    setDoc(
      doc(firestore, "WITHDRAW_REQUESTS", nowEpoch.toString()),
      mapWithdraw,
      { merge: true }
    );

    const updatedBalance = (parseFloat(currentWinningBalance) - amount).toFixed(
      0
    );
    setCurrentWinningBalance(updatedBalance);

    const newTransaction = {
      id: nowEpoch.toString(),
      amount: amount,
      customerId: userId,
      transactionType: "WITHDRAW_REQUESTS",
      date: now,
    };

    setWalletTransactionList([newTransaction, ...walletTransactionList]);
  };

  return (
    <div className="withdrawdetails">
      <div className="back_arrow_container">
        <FaChevronLeft onClick={handlePrevious} />
      </div>
      <div className="balance">
        <p>Current Balance</p>
        <h6>₹ {currentWinningBalance}</h6>
      </div>
      <form action="">
        <input
          type="text"
          placeholder="Enter Amount"
          className={amount ? `active enter` : "enter"}
          value={amount}
          onChange={(e) => priceHandler(Number(e.target.value))}
        />
      </form>
      <p>Enter amount to deposit to your account</p>
      <ul className="amounts">
        {price.map(({ price }, i) => (
          <li onClick={() => priceHandler(price)} key={i}>
            <p>₹{price}</p>
          </li>
        ))}
      </ul>

      {hasData ? (
        <div className="withdrw-bank">
          <h4>Withdraw to</h4>
          <div className="bank-details">
            <div className="bank">
              <p>Name: {holderName}</p>
              <p>Bank name: {bankName}</p>
              <p>Account number: {accountNumber}</p>
              <p>IFSC code: {ifceCode}</p>
            </div>
            <div className="edit-change">
              <div className="edit" onClick={handleButtonClick}>
                Edit
              </div>
              <input type="radio" />
            </div>
          </div>
        </div>
      ) : (
        <button className="btn-bank" onClick={handleButtonClick}>
          Add Bank Account
        </button>
      )}

      {showNewAddressForm && (
        <AccountDetails
          onSaveSuccess={fetchData}
          existingDetails={{ holderName, accountNumber, ifceCode, bankName }}
        />
      )}

      {/* <Button title="Redeem Now" /> */}
      <button onClick={handleRedeemCLick} className="redeembtn">
        Redeem Now
      </button>
    </div>
  );
};

export default WithdrawDetails;
