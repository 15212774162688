import React, { useEffect, useState } from "react";
import "./ConfirmedOrder.css";
import { Assets } from "../Assets/Assets";
import { MdDone } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";

const ConfirmedOrder = () => {
  const location = useLocation();
  const {
    orderId,
    orderDate,
    orderPrice,
    productQuantity,
    status,
    productImage,
    productPrice,
    deliveryRate,
    type,
  } = location.state || {};

  const [hasOrder, setHasOrder] = useState(false);

  console.log(location, "location...........");

  useEffect(() => {
    if (location) {
      setHasOrder(!setHasOrder);
    }
  }, [location]);

  const formattedOrderDate = orderDate
    ? format(new Date(orderDate), "PPpp")
    : "";

  return (
    <div className="confirmedorder">
      <div className="top-tea">
        <div className="tea-left-top">
          <img src={Assets.Tea} alt="" />
        </div>
        <div className="tea-top-total">
          {/* <h6>
            {location.state?.productQuantity} PACKETS X ₹
            {location.state?.productPrice}
          </h6> */}
          <p>{location.state?.productQuantity / 10}Kg</p>
          <p>
            {location.state?.productQuantity} PACKETS X ₹
            {location.state?.productPrice}
          </p>
          {/* <h5>₹{location.state?.orderPrice}</h5> */}
        </div>
      </div>

      {type === "Pick Up From Store" ? (
        <>
          <div className="confirmed-details">
            <div className="detail-box">
              <div className={`left-line`}>
                <div className="tick">
                  <MdDone />
                </div>
                <div className="line"></div>
              </div>
              <div className="right-detail">
                <h6> Order Confirmed {formattedOrderDate} </h6>
                <p>Your order has been placed.</p>
              </div>
            </div>
            <div className="detail-box">
              <div className="left-line">
                <div className="tick">
                  <MdDone />
                </div>
                <div
                  className={`${
                    location.state?.status === "DELIVERD" ? "line" : "grey-line"
                  }`}
                ></div>
              </div>
              <div className="right-detail">
                <h6>Product now available in the store</h6>
                <p>Your order has been Shipped.</p>
              </div>
            </div>
            <div className="detail-box">
              <div className="left-line">
                <div
                  className={`${
                    location.state?.status === "DELIVERD" ? "tick" : "grey-tick"
                  }`}
                >
                  <MdDone />
                </div>
              </div>
              <div className="right-detail">
                {status === "DELIVERD" ? (
                  <h6>Deliverd </h6>
                ) : (
                  <h6>Not Collected </h6>
                )}
              </div>
            </div>
          </div>

          {/* -------------------------------------------- end of pick from store */}
        </>
      ) : (
        <>
          <div className="confirmed-details">
            <div className="detail-box">
              <div className={`left-line`}>
                <div className="tick">
                  <MdDone />
                </div>
                <div className="line"></div>
              </div>
              <div className="right-detail">
                <h6> Order Confirmed, {formattedOrderDate} </h6>
                <p>Your order has been placed.</p>
                <p>Your item has been picked up by courier partner</p>
              </div>
            </div>
            <div className="detail-box">
              <div className="left-line">
                <div
                  className={` ${
                    location.state?.status === "DELIVERD" ||
                    location.state?.status === "SHIPPED"
                      ? "tick"
                      : "grey-tick"
                  }`}
                >
                  <MdDone />
                </div>
                <div
                  className={`${
                    location.state?.status === "DELIVERD" ? "line" : "grey-line"
                  }`}
                ></div>
              </div>

              <div className="right-detail">
                {status === "SHIPPED" || status === "DELIVERD" ? (
                  <h6>Shipped </h6>
                ) : (
                  <h6>Not Collected </h6>
                )}

                {/*                
                    <h6>Shipped, {formattedOrderDate}</h6>
                    <p>Your order has been Shipped.</p> */}

                {/* <h6>Shipped, 1pril 15, 2024</h6>
            <p>Your order has been Shipped.</p>
            <span>Tue, 16th Apr - 10:05 am</span> */}
              </div>
            </div>
            <div className="detail-box">
              <div className="left-line">
                {/* <div className={`tick${ type === "Delivery to Your Address" ? "grey-tick" : location.state?.status === "DISPATCHED" ? " tick" : "" }`} >
                      <MdDone />
                    </div> */}
                <div
                  className={` ${
                    location.state?.status === "DELIVERD"
                      ? "tick"
                      : "grey-tick "
                  }`}
                >
                  <MdDone />
                </div>
                {/* <div className={`line${type === "Delivery to Your Address" ? "grey-line" : "" }`} ></div> */}
              </div>

              <div className="right-detail">
                {location.state?.status === "DELIVERD" ? (
                  <p>Your order has been Delivered.</p>
                ) : (
                  <h6>Not Collected</h6>
                )}

                {/* <h6>Delivered on April 20, 2024</h6>
            <p>Your order has been Delivered.</p>
            <span>Sat, 20th Apr - 12:05 pm</span> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ConfirmedOrder;
