import React, { useState } from "react";
import "./TopNav.css";
import { Assets } from "../Assets/Assets";
import Profile from "../Profile/Profile";
import { FaUser } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const TopnavWeb = () => {

  const loginUserName = localStorage.getItem("loginUserName");
  const userIdIMg = localStorage.getItem("loginUserPhoto");
  const loginUserPhone = localStorage.getItem("loginUserPhone");
  const loginUserId = localStorage.getItem("loginUserId");
  const navigate = useNavigate();

  const [ showProfile, setShowProfile ] = useState(false)


  const handleProfile = ()=> {


    const navigateData = loginUserId == "" || loginUserId == null ? "/login" : document.body.classList.add("modal-active", "overflow-hidden");

    navigate(navigateData);

  }
  

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <div>
      <div className="web-topnav">
        <div className="logo-nav">

          <img className="boche_tex" src={Assets.Boche_web} alt="" />

        </div>
        <div onClick={handleProfile} className="user-top-web">
        {userIdIMg ? (
              <img className="user_img" src={userIdIMg} alt="User" />
            ) : loginUserName ? (
              <div className="user-initial_img">{getInitial(loginUserName)}</div>
            ) : (
              <div className="icon_user_defaul">
              <FaUser />

            </div>
            )}
            <div className="name_number">
            <h6>{loginUserName}</h6>
            <p> {loginUserPhone} </p>
          </div>
        
        </div>
      </div>

      <Profile />
    </div>
  );
};

export default TopnavWeb;
