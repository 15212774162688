// import React, { useEffect, useState } from "react";
// import "./StoreList.css";
// import serchIcn from "../Assets/Images/Vector_search.png";
// import location_logo from "../Assets/Images/location.svg";
// import location_green from "../Assets/Images/image 80.png";
// import { firestore } from "../../firebase";
// import { collection, getDocs } from "firebase/firestore";

// // import { geolocated } from 'react-geolocated';

// const StoreList = () => {

//   const [shopGroups, setShopGroups] = useState({}); // Stores grouped shops by district
//   const [selectedDistrict, setSelectedDistrict] = useState(null); // Keeps track of selected district
//   const [searchTerm, setSearchTerm] = useState(""); // Stores search term for filtering
//     const [currentLat, setCurrentLat] = useState(0);
//     const [currentLong, setCurrentLong] = useState(0);

//   const fetchShopsAndGroupByDistrict = async () => {
//     try {
//       const querySnapshotMain = collection(firestore, "STORE");
//       const querySnapshot = await getDocs(querySnapshotMain);
//       console.log("Query Snapshot:", querySnapshot);

//       let shops = {};

//       querySnapshot.forEach((doc) => {
//         const shopData = doc.data();
//         const shop = {
//           name: shopData.STORE_NAME || "",
//           district: shopData.DISTRICT || "",
//           address: shopData.STORE_ADDRESS || "",
//           state: shopData.STATE,
//           storeLat:shopData.LATITUDE,
//           storeLong:shopData.LONGITUDE
//         };
//         console.log("shop:", shop);
//         if (!shops[shop.district]) {
//           shops[shop.district] = [];
//         }

//         shops[shop.district].push(shop);
//       });

//       setShopGroups(shops);
//       console.log("shopGroups:", shopGroups);
//     } catch (error) {
//       console.error("Error fetching shops:", error);
//     }
//   };

//   useEffect(() => {
//     fetchShopsAndGroupByDistrict();
//   }, []);

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value.toLowerCase()); // Convert input to lowercase for case-insensitive search
//   };

//   const handleDistrictClick = (district) => {
//     setSelectedDistrict(district); // Update selected district on click
//   };

//   const launchMaps = (latitude, longitude, storeLatitude, storeLongitude) => {

//     const googleUrl =` https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${storeLatitude},${storeLongitude}`;

//     const appleUrl = `https://maps.apple.com/?sll=${latitude},${longitude}`;

//     // Attempt to open Google Maps URL
//     window.open(googleUrl, '_blank') || window.open(appleUrl, '_blank') || alert('Could not launch URL');
//   };

//   const requestLocationPermission = async () => {
//     if (navigator.permissions) {
//       try {
//         const result = await navigator.permissions.query({ name: 'geolocation' });
//         if (result.state === 'granted') {
//           return true;
//         } else if (result.state === 'prompt') {
//           return new Promise((resolve, reject) => {
//             navigator.geolocation.getCurrentPosition(
//               () => resolve(true),
//               () => resolve(false)
//             );
//           });
//         } else {
//           return false;
//         }
//       } catch (error) {
//         console.error('Error checking geolocation permission', error);
//         return false;
//       }
//     }
//     return false;
//   };

//   const getCurrentPosition = async (storeLat,storeLong ) => {
//     const hasPermission = await requestLocationPermission();
//     if (!hasPermission) {
//       alert('Location permissions are denied');
//       return;
//     }

//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           console.log(position, "=== fetched postion data of user")

//           setCurrentLat(position.coords.latitude);
//           setCurrentLong(position.coords.longitude);
//           launchMaps(position.coords.latitude, position.coords.longitude, storeLat, storeLong);
//         },
//         (error) => {
//           console.error('Error fetching location', error);
//           alert('Error fetching location');
//         }
//       );
//     } else {
//       alert('Geolocation is not supported by this browser.');
//     }
//   };

// // geolocated({
// //   positionOptions: {
// //     enableHighAccuracy: true,
// //   },
// //   userDecisionTimeout: 5000,
// // })(StoreList);

//   return (
//     <div className="store">
//       <div className="Store_search_bar">
//         <input
//           placeholder="Search your district here" // Adjusted placeholder for clarity
//           type="text"
//           value={searchTerm}
//           onChange={handleSearchChange}
//         />
//         <div className="icon_box">
//           <img src={serchIcn} alt="Search Icon" />
//           <p>Search District</p>
//         </div>
//       </div>

//       <div className="content_container">
//         <div className="content_head">
//           <h4>Working Hours:</h4>
//           <p>10:00 am - 06:00 pm</p>
//         </div>

//         <div className="location_container">
//           <h5>Select Your District</h5>

//           <div className="district">
//             {shopGroups &&
//               Object.keys(shopGroups)
//                 .filter((district) =>
//                   district.toLowerCase().includes(searchTerm)
//                 ) // Filter districts based on search term
//                 .map((district, index) => (
//                   <div
//                     key={index}
//                     className={`district_item ${
//                       selectedDistrict === district ? "active" : ""
//                     }`} // Add active class for styling when clicked
//                     onClick={() => handleDistrictClick(district)}
//                   >
//                     <img src={location_logo} alt="Location Logo" />
//                     <p>{district}</p>
//                   </div>
//                 ))}
//           </div>

//           <div className="address_container">
//             {selectedDistrict && ( // Only show address container if a district is selected
//               <div className="address_container_head">
//                 <div className="Head_text_1">Branch Name</div>
//                 <div className="Head_text_2">Address</div>
//               </div>
//             )}

//             {selectedDistrict &&
//               shopGroups[selectedDistrict] && ( // Only show address items if district is selected and data exists
//                 <div className="address_items_container">
//                   {shopGroups[selectedDistrict].map((shop, shopIndex) => (
//                     <div className="address_items" key={`${shopIndex}`}>
//                       <div className="address_logo_container">
//                         <div className="item_1">
//                           <p>{shop.name}</p>
//                         </div>
//                         <div className="item_2">
//                           <p>{shop.address}</p>
//                         </div>
//                       </div>
//                       <div className="item_3">
//                         {/* <img onClick={()=> getCurrentPosition(shop.storeLat,shop.storeLong)}  src={location_green} alt="Location Green" /> */}
//                         <img onClick={() => getCurrentPosition(shop.storeLat, shop.storeLong)} src={location_green} alt="Location Green" />

//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StoreList;

import React, { useEffect, useState, useRef } from "react";
import "./StoreList.css";
import serchIcn from "../Assets/Images/Vector_search.png";
import location_logo from "../Assets/Images/location.svg";
import location_green from "../Assets/Images/image 80.png";
import { firestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const StoreList = () => {
  const [shopGroups, setShopGroups] = useState({}); // Stores grouped shops by district
  const [selectedDistrict, setSelectedDistrict] = useState(null); // Keeps track of selected district
  const [searchTerm, setSearchTerm] = useState(""); // Stores search term for filtering
  const [currentLat, setCurrentLat] = useState(0);
  const [currentLong, setCurrentLong] = useState(0);
  const [shopData, setShopData] = useState([]);
  const [isState, setIsState] = useState();

  const addressContainerRef = useRef(null); // Ref for the address container

  const fetchShopsAndGroupByDistrict = async () => {
    try {
      const querySnapshotMain = collection(firestore, "STORE");
      const querySnapshot = await getDocs(querySnapshotMain);
      console.log("Query Snapshot:", querySnapshot);

      let shops = {};
      let states = {};

      querySnapshot.forEach((doc) => {
        const shopData = doc.data();
        const shop = {
          name: shopData.STORE_NAME || "",
          district: shopData.DISTRICT || "",
          address: shopData.STORE_ADDRESS || "",
          state: shopData.STATE,
          storeLat: shopData.LATITUDE,
          storeLong: shopData.LONGITUDE,
        };
        setShopData(shop);

        console.log("shop initial fetch dataaaaaaaaaaaa:", shop);
        if (!shops[shop.district]) {
          shops[shop.district] = [];
        }

        shops[shop.district].push(shop);

        if (!states[shop.state]) {
          states[shop.state] = [];
        }
        states[shop.state].push(shop);

        console.log(shops, "this is shopss..........................");
        console.log(states, "hyyyyyyyyyyyyyyyyyyyyyyyyyy");
      });

      console.log(shopData, "this is shop data ,,,,,,,,,,,,,,,,,");

      setShopGroups(shops);
      setIsState(states);
      console.log(
        "shopGroups:",
        shopGroups.latitude,
        "............................"
      );
    } catch (error) {
      console.error("Error fetching shops:", error);
    }
  };

  useEffect(() => {
    fetchShopsAndGroupByDistrict();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase()); // Convert input to lowercase for case-insensitive search
  };

  const handleDistrictClick = (district) => {
    setSelectedDistrict(district); // Update selected district on click
    if (addressContainerRef.current) {
      addressContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const launchMaps = (latitude, longitude, storeLatitude, storeLongitude) => {
    const googleUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${storeLatitude},${storeLongitude}`;
    const appleUrl = `https://maps.apple.com/?sll=${latitude},${longitude}`;

    // Attempt to open Google Maps URL
    window.open(googleUrl, "_blank") ||
      window.open(appleUrl, "_blank") ||
      alert("Could not launch URL");
  };

  const requestLocationPermission = async () => {
    if (navigator.permissions) {
      try {
        const result = await navigator.permissions.query({
          name: "geolocation",
        });
        if (result.state === "granted") {
          return true;
        } else if (result.state === "prompt") {
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              () => resolve(true),
              () => resolve(false)
            );
          });
        } else {
          return false;
        }
      } catch (error) {
        console.error("Error checking geolocation permission", error);
        return false;
      }
    }
    return false;
  };

  const getCurrentPosition = async (storeLat, storeLong) => {
    const hasPermission = await requestLocationPermission();
    if (!hasPermission) {
      alert("Location permissions are denied");
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position, "=== fetched position data of user");

          setCurrentLat(position.coords.latitude);
          setCurrentLong(position.coords.longitude);
          launchMaps(
            position.coords.latitude,
            position.coords.longitude,
            storeLat,
            storeLong
          );
        },
        (error) => {
          console.error("Error fetching location", error);
          alert("Error fetching location");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="store">
      <div className="Store_search_bar">
        <input
          placeholder="Search your district here" // Adjusted placeholder for clarity
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="icon_box">
          <img src={serchIcn} alt="Search Icon" />
          <p>Search District</p>
        </div>
      </div>

      <div className="content_container">
        <div className="content_head">
          <h4>Working Hours:</h4>
          <p>10:00 am - 06:00 pm</p>
        </div>
        <div className="location-stateselect">
          <h5> Select Your State </h5>
          <select name="" id="">
            {isState &&
              Object.keys(isState)
                .filter((state) =>
                  state.toLowerCase().includes(searchTerm.toLowerCase())
                ) // Filter districts based on search term
                .map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
          </select>
        </div>

        <div className="location_container">
          <h5>Select Your District</h5>

          <div className="district">
            {shopGroups &&
              Object.keys(shopGroups)
                .filter((district) =>
                  district.toLowerCase().includes(searchTerm)
                ) // Filter districts based on search term
                .map((district, index) => (
                  <div
                    key={index}
                    className={`district_item ${
                      selectedDistrict === district ? "active" : ""
                    }`} // Add active class for styling when clicked
                    onClick={() => handleDistrictClick(district)}
                  >
                    <img src={location_logo} alt="Location Logo" />
                    <p>{district}</p>
                  </div>
                ))}
          </div>

          <div className="address_container" ref={addressContainerRef}>
            {selectedDistrict && ( // Only show address container if a district is selected
              <div className="address_container_head">
                <div className="Head_text_1">Branch Name</div>
                <div className="Head_text_2">Address</div>
              </div>
            )}

            {selectedDistrict &&
              shopGroups[selectedDistrict] && ( // Only show address items if district is selected and data exists
                <div className="address_items_container">
                  {shopGroups[selectedDistrict].map((shop, shopIndex) => (
                    <div className="address_items" key={`${shopIndex}`}>
                      <div className="address_logo_container">
                        <div className="item_1">
                          <p>{shop.name}</p>
                        </div>
                        <div className="item_2">
                          <p>{shop.address}</p>
                        </div>
                      </div>
                      <div className="item_3">
                        <img
                          onClick={() => {
                            if (shop.storeLat && shop.storeLong) {
                              getCurrentPosition(shop.storeLat, shop.storeLong);
                            } else {
                              alert(
                                "Location information is not available for this shop."
                              );
                            }
                          }}
                          src={location_green}
                          alt="Location Green"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreList;
