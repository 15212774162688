import React, { useState, useEffect } from "react";
import "./ProfileEdit.css";
import { FaUserAlt } from "react-icons/fa";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { firestore, storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { useCount } from "../../Context/Context";
import { ClipLoader } from "react-spinners";

const ProfileEdit = ({ setShowEditPopup }) => {
  const userId = localStorage.getItem("loginUserId");
  const navigator = useNavigate();

  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const { profileLoader, setProfileLoader } = useCount();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        const userDoc = await getDoc(doc(firestore, "USERS", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setName(userData.NAME || "");
          setState(userData.STATE || "");
          setPhone(userData.PHONE || "");
          if (userData.PROFILE_IMAGE) {
            setImageURL(userData.PROFILE_IMAGE);
          }
        }
      }
    };

    fetchUserData();
  }, [userId]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setImageURL(URL.createObjectURL(file));
    }
  };

  const handleImageUpload = async () => {
    try {
      if (image) {
        const fileRef = ref(storage, `${userId}/${image.name}`);
        await uploadBytes(fileRef, image);
        return await getDownloadURL(fileRef);
      }
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };

  const validate = () => {
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Name is required.";
    }

    const phoneRegex = /^[0-9]{10}$/;
    if (!phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(phone)) {
      newErrors.phone = "Phone number must be a valid 10-digit number.";
    }

    if (!state) {
      newErrors.state = "State is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return;
    }

    setProfileLoader(true);




    try {
      const imageUrl = await handleImageUpload();

      if (!userId) {
        throw new Error("User ID is missing.");
      }

      const userRef = doc(firestore, "USERS", userId);
      const customerRef = doc(firestore, "CUSTOMERS", userId);

      const userUpdate = {
        NAME: name,
        STATE: state,
      };

      const customerUpdate = {
        NAME: name,
        PHONE: phone,
        STATE: state,
      };

      if (imageUrl) {
        userUpdate.PROFILE_IMAGE = imageUrl;
      }

      await setDoc(userRef, userUpdate, { merge: true });
      await setDoc(customerRef, customerUpdate, { merge: true });

      localStorage.setItem("loginUserName", name);
      localStorage.setItem("loginUserPlace", state);

      if (phone) {
        localStorage.setItem("loginUserPhone", phone);
      }

      if (imageUrl) {
        localStorage.setItem("loginUserPhoto", imageUrl);
      }
      setProfileLoader(false);
      alert("Profile updated successfully!");
      setShowEditPopup(false);
    } catch (error) {
      console.error("Error updating profile: ", error);
      setProfileLoader(false);
      alert("An error occurred while updating profile. Please try again.");
    }
  };

  return (
    <div className="profile-edit">
      <form onSubmit={handleSubmit}>
        {imageURL ? (
          <img src={imageURL} alt="Selected" className="image-preview" />
        ) : (
          <FaUserAlt className="user-icon" />
        )}
        <input type="file" accept="image/*" onChange={handleImageChange} />

        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={errors.name ? "input-error" : ""}
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>

        <div className="form-group">
          <input
            type="tel"
            placeholder="Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={errors.phone ? "input-error" : ""}
          />
          {errors.phone && (
            <span className="error-message">{errors.phone}</span>
          )}
        </div>

        <div className="form-group">
          <select
            value={state}
            onChange={(e) => setState(e.target.value)}
            className={errors.state ? "input-error" : ""}
          >
            <option value="">Select State</option>
            <option value="Andhra Pradesh">Andhra Pradesh</option>
            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
            <option value="Assam">Assam</option>
            <option value="Bihar">Bihar</option>
            <option value="Chhattisgarh">Chhattisgarh</option>
            <option value="Goa">Goa</option>
            <option value="Gujarat">Gujarat</option>
            <option value="Haryana">Haryana</option>
            <option value="Himachal Pradesh">Himachal Pradesh</option>
            <option value="Jharkhand">Jharkhand</option>
            <option value="Karnataka">Karnataka</option>
            <option value="Kerala">Kerala</option>
            <option value="Madhya Pradesh">Madhya Pradesh</option>
            <option value="Maharashtra">Maharashtra</option>
            <option value="Manipur">Manipur</option>
            <option value="Meghalaya">Meghalaya</option>
            <option value="Mizoram">Mizoram</option>
            <option value="Nagaland">Nagaland</option>
            <option value="Odisha">Odisha</option>
            <option value="Punjab">Punjab</option>
            <option value="Rajasthan">Rajasthan</option>
            <option value="Sikkim">Sikkim</option>
            <option value="Tamil Nadu">Tamil Nadu</option>
            <option value="Telangana">Telangana</option>
            <option value="Tripura">Tripura</option>
            <option value="Uttar Pradesh">Uttar Pradesh</option>
            <option value="Uttarakhand">Uttarakhand</option>
            <option value="West Bengal">West Bengal</option>
          </select>
          {errors.state && (
            <span className="error-message">{errors.state}</span>
          )}
        </div>

        {profileLoader ? (
          <ClipLoader color="#36d7b7" />
        ) : (
          <button type="submit">Submit</button>
        )}
      </form>
    </div>
  );
};

export default ProfileEdit;
