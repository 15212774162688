import React, { useState } from "react";
import "./Header.css";
import { Assets } from "../Assets/Assets";
import Profile from "../Profile/Profile";
import { FaUser } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const [showProfile, setShowProfile] = useState(false);

  const userImg = localStorage.getItem("loginUserPhoto");
  const loginUserName = localStorage.getItem("loginUserName");
  const loginUserId = localStorage.getItem("loginUserId");

  const navigate = useNavigate();

  const handleUserImgClick = () => {
    const navigateData =
      loginUserId == "" || loginUserId == null
        ? "/login"
        : document.body.classList.add("modal-active", "overflow-hidden");

    navigate(navigateData);
  };

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  return (
    <div>
      <header>
        <div className="user-img" onClick={handleUserImgClick}>
          {userImg ? (
            <img src={userImg} alt="User" />
          ) : loginUserName ? (
            <div className="user-text">{getInitial(loginUserName)}</div>
          ) : (
            <div className="icon_user_defaul">
              <FaUser />
            </div>
          )}
        </div>

        <h3>boCHE MART</h3>
        <div className="boche-logo">
          <img src={Assets.Logo} alt="" />
        </div>
      </header>
      <Profile />
    </div>
  );
};
