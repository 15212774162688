import React, { useState, useEffect } from "react";
import "./Privacy.css";
import { TopNav } from "../../Components/TopNav/TopNav";
import TopnavWeb from "../../Components/TopNav/TopnavWeb";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

export const Privacy = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {isMobile ? <TopNav title="Privacy Policy" /> : <TopnavWeb />}
      <div className="contents">
        <div className="head">
          <h6>Privacy Policy</h6>
        </div>
        <p>
          Patronymic technologies and industries private limited. ("BOCHEMART"
          or "We"), a group company of BOBY CHEMMANUR INTERNATIONAL GROUP
          ("BCIG" or "We"), respects your privacy and is committed to protecting
          it through our compliance with our Privacy Policy. This Privacy Notice
          governs the manner in which www.bochemart.com a brand owned by
          Patronymic technologies and industries private limited (referred to as
          "Boche Mart," "we," "us," or "our"), collects, uses, shares,
          maintains, and discloses Personal Data collected from users (referred
          to as "Users" or "you") of the website ("site"). We encourage you to
          read our Privacy Notice regularly and particularly before providing
          any Personal Data. We may amend this Privacy Notice from time to time
          to reflect changes in our data handling practices.
        </p>

        <div className="intro">
          <h6>Data Collection and Processing</h6>
        </div>
        <p>
          We collect, process, use, store, disclose, transfer, or otherwise make
          available ("Processing" or "Process") your Personal Data as legally
          permitted under the Digital Personal Data Protection Act, 2023, and
          other applicable Data Protection Regulations. The privacy practices
          outlined in this Privacy Notice pertain to this website only. If you
          are redirected to other websites or provide personal data to any of
          those websites, please review the privacy notices posted there. Types
          of Data We Collect and How We Collect It 1. Personal Data You Provide:
          We receive and store any data you provide in relation to our Ser
          vices. Examples include your name, address, contact details such as
          email address and phone number, as well as information about your
          interactions with our website and the services/products accessed or
          purchased by you. 2. Automatically Collected Personal Data: We
          automatically collect and store certain types of data about your use
          of our Services, including data about your interaction with our
          website. This data is used to personalize your experience, process
          transactions, and deliver orders. We use technologies like cookies to
          gather this information. Entering Contests or Promotions: If you
          participate in a promotional event, we will collect your name, contact
          information, and any other information you provide.
        </p>

        <div className="intro">
          <h6>How We Use Your Data</h6>
        </div>
        <p>
          We may use the data we collect for various purposes, including
          processing orders, marketing and advertising, administrative tasks,
          customer service, research and analytics, and legal compliance.
        </p>

        <div className="intro">
          <h6>Legal Basis of Processing Personal Data</h6>
        </div>
        <p>
          We process your Personal Data based on your consent or our legitimate
          interests, including legal compliance and protection of our interests
          and those of others
        </p>

        <div className="intro">
          <h6>Disclosure of Your Data</h6>
        </div>
        <p>
          We may disclose your Personal Data to various entities as described in
          this privacy policy notice, including subsidiaries, service providers,
          advertisers, and in compliance with legal obligations.
        </p>

        <div className="intro">
          <h6>Billing and Payments</h6>
        </div>
        <p>
          We use third-party payment processors to securely process your payment
          information. We ensure that our partners comply with applicable
          regulatory and industry standards.
        </p>

        <div className="intro">
          <h6>Minors Data</h6>
        </div>
        <p>
          Our Site is not intended for individuals under the age of 18, and we
          do not knowingly collect Personal Data of children.
        </p>

        <div className="intro">
          <h6>Data Retention</h6>
        </div>
        <p>
          We retain your Personal Data for as long as necessary for the purposes
          described in this Notice and to comply with legal obligations.
        </p>

        <div className="intro">
          <h6>Data Principal Rights</h6>
        </div>
        <p>
          You have rights regarding your Personal Information under applicable
          laws, including access, correction, erasure, and the right to raise
          grievances.
        </p>

        <div className="intro">
          <h6>Cross Border Data Transfer</h6>
        </div>
        <p>
          We ensure that cross-border data transfers comply with applicable laws
          and standards.
        </p>

        <div className="intro">
          <h6>Data Security</h6>
        </div>
        <p>
          We employ industry-standard security measures to protect your Personal
          Data.
        </p>

        <div className="intro">
          <h6>Complaints or Inquiry</h6>
        </div>
        <p>
          For inquiries or complaints regarding this Privacy Notice, please
          contact us at customercare
          <a href="mailto:care@bochemart.com">care@bochemart.com</a>
          You also have the right to complain to a Data Protection Board about
          our collection and use of your Personal Data.
        </p>
        <div className="intro">
          <h6>Duties of Data Principals</h6>
        </div>
        <p>
          As a data principal, you have certain responsibilities under the
          Digital Personal Data Protection Act, 2023 ("the Act"). These include
          complying with applicable laws while exercising your rights under the
          Act, refraining from impersonating others while providing Personal
          Data, and ensuring that grievances or complaints filed with the Data
          Protection Board of India ("the Board") are genuine and not frivolous.
        </p>
        <div className="intro">
          <h6>Cross-Border Data Transfer</h6>
        </div>
        <p>
          When we transfer personal data to countries outside India, we ensure
          that such transfers comply with this Privacy Notice and applicable
          Data Protection laws. We adhere to the decisions of the Government of
          India regarding the restriction of Personal Data transfers to
          blacklisted countries or implement contracts with standard safeguards
          for such transfers.
        </p>

        <div className="intro">
          <h6>Data Security</h6>
        </div>
        <p>
          We take measures to protect your Personal Data from unauthorized
          access, alteration, disclosure, or destruction. Our security practices
          align with industry standards, and we expect the same level of
          protection from our service providers.
        </p>
        <div className="intro">
          <h6>Changes to this Privacy Notice</h6>
        </div>
        <p>
          We reserve the right to update or modify this Privacy Notice from time
          to time. Any changes will be effective immediately upon posting the
          revised Privacy Notice on our website. We encourage you to review this
          Notice periodically to stay informed about how we are protecting your
          Personal Data.
        </p>
        <div className="intro">
          <h6>Complaints or Inquiry</h6>
        </div>
        <p>
          If you have any questions or concerns about this Privacy Notice, our
          websites practices, or your interactions with our site, please reach
          out to us at
          <a href="mailto:care@bochemart.com">care@bochemart.com</a>
          Additionally, you have the right to file a complaint with a Data
          Protection Board ("The Board") regarding our collection and use of
          your Personal Data. Thank you for taking the time to read our Privacy
          Notice. We are committed to safeguarding your privacy and ensuring the
          security of your Personal Data
        </p>

        <Link to="/home" className="link">
          <button>Ok</button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};
