// import React, { useState } from "react";
// import "./PaymentWeb.css";
// import SelectAddress from "../SelectAddress/SelectAddress";
// import SelectPayment from "../SelectPayment/SelectPayment";
// import DeliverySummery from "../DeliverySummery/DeliverySummery";
// import TopnavWeb from "../TopNav/TopnavWeb";
// import { CSSTransition } from "react-transition-group";
// import { fetchDeliveryChargeAndAvailability } from "../SelectAddress/DeliverCharge";
// import { useCount } from "../../Context/Context";
// import { ClipLoader } from "react-spinners";

// const PaymentWeb = () => {
//   const [step, setStep] = useState("address");
//   const [selectedAddress, setSelectedAddress] = useState("");
//   const [variableSelect, setVariableSelect] = useState("");
//   const { count, ready,pincode } = useCount();
//   const [deliveryRate, setDeliveryRate] = useState();
//   const [ loading,setLoading]= useState(false);

//   console.log(ready, "ready from firt time on payment");

//   // console.log(pincode , "pin code at payment web ")

//   // const handleContinueToPayment = async (ready) => {

//   //   // const pincode = {pincode} ;
//   //   const proQty = count;
//   //   const data = await fetchDeliveryChargeAndAvailability(pincode, proQty);
//   //   console.log(data);

//   //   console.log(ready, "this ready second timee ");

//   //   if (ready === true) {

//   //     setDeliveryRate(data.rate);
//   //   }

//   //   // if (selectedAddress.length > 0) {
//   //   //   setStep("payment");
//   //   // } else {
//   //   //   alert("Pls select your addres");
//   //   if (
//   //     variableSelect == "Delivery To Address" ||
//   //     variableSelect == "Pick Up From Store"
//   //   ) {
//   //     setStep("payment");
//   //   } else {
//   //     alert("Pls select your addres");
//   //   }
//   //   // }
//   // };

//   // const handleContinueToPayment = async (ready) => {
//   //   try {
//   //     setLoading(true)
//   //     console.log(pincode, "inside function mmmmm")
//   //     const proQty = count;

//   //     let pincodeString = pincode.toString();
//   //     console.log(pincodeString, "stinged pin code .............")
//   //     console.log(typeof pincodeString);

//   //     // const pincode = "676121";
//   //     const data = await fetchDeliveryChargeAndAvailability(pincodeString.trim(), proQty);
//   //     console.log(data);

//   //     if (ready === true ) {
//   //       if (data && data.rate) {
//   //         setDeliveryRate(data.rate);
//   //       } else {
//   //         console.error("Data does not contain rate:", data);
//   //         alert("Error fetching delivery rate. Please try again.");
//   //         return;
//   //       }
//   //       if (
//   //         variableSelect === "Delivery To Address" ||
//   //         variableSelect === "Pick Up From Store"
//   //       ) {
//   //         setLoading(false)

//   //         setStep("payment");
//   //       } else {

//   //         alert("Please select your address");
//   //         setLoading(false);
//   //       }
//   //     }

//   //     else {
//   //       alert("select an address")
//   //       setLoading(false)

//   //     }

//   //   } catch (error) {
//   //     console.error("Error in handleContinueToPayment:", error);
//   //     alert("An error occurred. Please try again.");
//   //   }
//   // };

//   const handleContinueToPayment = async () => {
//     try {
//       setLoading(true);
//       console.log(pincode, "inside function mmmmm");
//       const proQty = count;

//       let pincodeString = pincode.toString();
//       console.log(pincodeString, "stinged pin code .............");
//       console.log(typeof pincodeString);

//       const data = await fetchDeliveryChargeAndAvailability(pincodeString.trim(), proQty);
//       console.log(data);

//       if (variableSelect === "Delivery To Address") {
//         if (data && data.rate) {
//           setDeliveryRate(data.rate);
//           setStep("payment");
//         } else {
//           console.error("Data does not contain rate:", data);
//           alert("Error fetching delivery rate. Please try again.");
//         }
//       } else {
//         alert("Please select 'Delivery To Address' as your address option.");
//       }
//     } catch (error) {
//       console.error("Error in handleContinueToPayment:", error);
//       alert("An error occurred. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <TopnavWeb />
//       <div className="payment-web">
//         <div className="web-payment-box">
//           <CSSTransition
//             in={step === "address"}
//             timeout={500}
//             classNames="fade"
//             unmountOnExit
//           >
//             <SelectAddress
//               setSelectedAddress={setSelectedAddress}
//               setVariableSelect={setVariableSelect}
//             />
//           </CSSTransition>

//           <CSSTransition
//             in={step === "payment"}
//             timeout={500}
//             classNames="fade"
//             unmountOnExit
//           >
//             <SelectPayment setDeliveryRate={setDeliveryRate} setStep={setStep} />
//           </CSSTransition>
//         </div>

//         <div className="delivery-summery-main">
//           <DeliverySummery deliveryRate={deliveryRate} />
//           {step === "address" && (
//             <div className="switch-bt">
//               <button
//                 className="btn-del"
//                 onClick={() => handleContinueToPayment(ready)}
//               >
//                {loading ? <ClipLoader color="#fff" size={16} /> : "Place Order"}
//               </button>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default PaymentWeb;

// import React, { useState } from "react";
// import "./PaymentWeb.css";
// import SelectAddress from "../SelectAddress/SelectAddress";
// import SelectPayment from "../SelectPayment/SelectPayment";
// import DeliverySummery from "../DeliverySummery/DeliverySummery";
// import TopnavWeb from "../TopNav/TopnavWeb";
// import { CountProvider } from "../../Context/Context";
// import { CSSTransition } from "react-transition-group";

// const PaymentWeb = () => {
//   const [step, setStep] = useState("address");

//   const handleContinue = () => {
//     setStep("payment");
//   };

//   return (
//     <CountProvider>
//       <TopnavWeb />
//       <div className="payment-web">
//         <div className="web-payment-box">
//           <CSSTransition
//             in={step === "address"}
//             timeout={500}
//             classNames="fade"
//             unmountOnExit
//           >
//             <SelectAddress />
//           </CSSTransition>
//           <CSSTransition
//             in={step === "payment"}
//             timeout={500}
//             classNames="fade"
//             unmountOnExit
//           >
//             <SelectPayment setStep={setStep} />
//           </CSSTransition>
//         </div>
//         <div className="delivery-summery-main">
//           <DeliverySummery />
//           {step === "address" && (
//             <div className="switch-bt">
//               <button className="btn-del" onClick={handleContinue}>
//                 Continue
//               </button>
//             </div>
//           )}
//         </div>
//       </div>
//     </CountProvider>
//   );
// };

// export default PaymentWeb;

import React, { useEffect, useState } from "react";
import "./PaymentWeb.css";
import SelectAddress from "../SelectAddress/SelectAddress";
import SelectPayment from "../SelectPayment/SelectPayment";
import DeliverySummery from "../DeliverySummery/DeliverySummery";
import TopnavWeb from "../TopNav/TopnavWeb";
import { CSSTransition } from "react-transition-group";
import { fetchDeliveryChargeAndAvailability } from "../SelectAddress/DeliverCharge";
import { useCount } from "../../Context/Context";
import { ClipLoader } from "react-spinners";
import Footer from "../../Pages/Footer/Footer";
// import { useParams } from 'react-router-dom';

const PaymentWeb = () => {
  const [step, setStep] = useState("address");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [variableSelect, setVariableSelect] = useState("");
  const {
    count,
    ready,
    setReady,
    pincode,
    showCount,
    setShowCount,
    deliveryAddress,
  } = useCount();
  const [deliveryRate, setDeliveryRate] = useState();
  const [courierId, setCourierId] = useState(0);
  const [courierName, setCourierName] = useState("");
  const [estimatedDays, setEstimatedDays] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(pincode, "pincode ......");

  console.log(count, "prd qty ..................");

  useEffect(() => {
    setShowCount(true);
  }, []);

  useEffect(() => {
    if (step === "payment") {
      setShowCount(false);
    } else if (step === "address") {
      setShowCount(true);
    }
  }, [step, setShowCount]);

  // console.log(showCount, "show count change. ...........")

  let intRate;

  const handleContinueToPayment = async (ready) => {
    // try {
    //   setLoading(true);
    //   // setShowCount(!showCount);

    //   if (!variableSelect) {
    //     alert("Select your delivery mode");
    //     setLoading(false);
    //     return;
    //   }


    //   if (ready && variableSelect === "Delivery to Your Address") {
    //     if (deliveryAddress) {
    //       const proQty = count;
    //       let pincodeString = pincode.toString().trim();

    //       const data = await fetchDeliveryChargeAndAvailability(
    //         pincodeString,
    //         proQty
    //       );
    //       console.log(data, " api data ..............");

    //       if (data && data.rate) {
    //         intRate = Math.ceil(data.rate);

    //         setDeliveryRate(intRate);
    //         setEstimatedDays(data.estimated_delivery_days);
    //         setCourierName(data.courier_name);
    //         setCourierId(data.courier_company_id);

    //         // console.log(courierName, "courierName")
    //         // console.log(courierId, "courierId")
    //         // console.log(estimatedDays, "estimatedDays")
    //         // // console.log(deductPayAmount, "deductPayAmount")
    //         // // console.log(dedeuctDepositAmonunt, "dedeuctDepositAmonunt")
    //         // // console.log(deductWinningAmount, "deductWinningAmount")
    //         // console.log(deliveryRate, "deliveryRate")
    //       } else {
    //         console.error("Data does not contain rate:", data);
    //         alert("Error fetching delivery rate. Please try again.");
    //         setLoading(false);
    //         return;
    //       }




    //       if (variableSelect === "Delivery to Your Address") {
    //         setStep("payment");
    //         setLoading(false);
    //       }
    //     } else {
    //       alert("Select your address");
    //       setLoading(false);
    //     }

    //     // -------------------------------------------------------------- else
    //   } else if (variableSelect === "Pick Up From Store") {
    //     setStep("payment");
    //     setReady(false);
    //     setLoading(false);
    //     console.log(ready, "ready ..................");
    //   } else {
    //     alert("select your delvery mode");
    //     setLoading(false);
    //     setShowCount(true);
    //   }
    // } catch (error) {
    //   console.error("Error in handleContinueToPayment:", error);
    //   alert("An error occurred. Please try again.");
    //   setLoading(false);
  
    
    try {
      setLoading(true);

      if (!variableSelect) {
        alert("Select your delivery mode");
        setLoading(false);
        return;
      }

      if (ready && variableSelect === "Delivery to Your Address") {
        if (deliveryAddress) {
          const proQty = count;
          let pincodeString = pincode.toString().trim();

          const data = await fetchDeliveryChargeAndAvailability(pincodeString, proQty);
          if (data && data.rate) {
            setDeliveryRate(Math.ceil(data.rate));
            setEstimatedDays(data.estimated_delivery_days);
            setCourierName(data.courier_name);
            setCourierId(data.courier_company_id);
          } else {
            console.error("Data does not contain rate:", data);
            alert("Error fetching delivery rate. Please try again.");
            setLoading(false);
            return;
          }
          setStep("payment");
          setLoading(false);
        } else {
          alert("Select your address");
          setLoading(false);
        }
      } else if (variableSelect === "Pick Up From Store") {
        setStep("payment");
        setReady(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in handleContinueToPayment:", error);
      alert("An error occurred. Please try again.");
      setLoading(false);

  
  }
  };

  return (
    <>
      <TopnavWeb />
      <div className="payment-web">
        <div className="web-payment-box">
          <CSSTransition
            in={step === "address"}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <SelectAddress
              setSelectedAddress={setSelectedAddress}
              setVariableSelect={setVariableSelect}
            />
          </CSSTransition>

          <CSSTransition
            in={step === "payment"}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <SelectPayment
              deliveryRate={deliveryRate}
              estimatedDays={estimatedDays}
              courierName={courierName}
              courierId={courierId}
              setDeliveryRate={setDeliveryRate}
              setStep={setStep}
              setCourierId={setCourierId}
              setCourierName={setCourierName}
              setEstimatedDays={setEstimatedDays}
            />
          </CSSTransition>
        </div>

        <div className="delivery-summery-main">
          <DeliverySummery deliveryRate={deliveryRate} />
          {step === "address" && (
            <div className="switch-bt">
              <button
                className="btn-del"
                onClick={() => handleContinueToPayment(ready)}
              >
                {loading ? <ClipLoader color="#fff" size={16} /> : "Continue"}
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentWeb;
