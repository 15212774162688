import React, { createContext, useContext, useEffect, useState } from "react";
import { query, collection, where, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";

const CountContext = createContext();

export const CountProvider = ({ children }) => {
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [lastUsedDeliveryName, setLastUsedDeliveryNameCtx] = useState("");
  const [selecteVariable, setSelecteVariable] = useState("");
  const [lastUsedDeliveryNumber, setLastUsedDeliveryNumberCtx] = useState("");
  const [lastUsedDeliveryAddress, setLastUsedDeliveryAddressCtx] = useState("");
  const [selecteaddress, setselecteAddressCtx] = useState("");
  const [ccLoading, setCcLoading] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [profileLoader, setProfileLoader] = useState(false);
  const [currentDepositBalance, setCurrentDepositBalance] = useState(0);
  const [currentWinningBalance, setCurrentWinningBalance] = useState(0);
  const [pincode, setPincode] = useState();
  const [showCount, setShowCount] = useState(true);
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [paymentLoader, setPaymentLoader] = useState(true);

  // console.log(currentDepositBalance);
  // console.log(lastUsedDeliveryName, "teateContextttttttttttttttttttttttttttttt");

  // console.log(lastUsedDeliveryNumber, "lastUsedDeliveryNumber on contexttttttttttttttuuuuuuuuuuuuuu")

  // console.log(
  //   deliveryAddress,
  //   "deliveryaddress,,,,,,,,, from  context .................."
  // );

  console.log(pincode, "pincode .............. from cotext");

  const increment = () => {
    if (count < 10) {
      setCount(count + 1);
    }
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const [currentWalletBalance, setCurrentWalletBalance] = useState();

  const [ready, setReady] = useState(false);

  const totalPrice = count * 40;

  return (
    <CountContext.Provider
      value={{
        loading,
        setLoading,
        count,
        totalPrice,
        increment,
        decrement,
        selecteVariable,
        setSelecteVariable,
        lastUsedDeliveryName,
        setLastUsedDeliveryNameCtx,
        lastUsedDeliveryNumber,
        setLastUsedDeliveryNumberCtx,
        lastUsedDeliveryAddress,
        setLastUsedDeliveryAddressCtx,
        selecteaddress,
        setselecteAddressCtx,
        ccLoading,
        setCcLoading,
        loginLoader,
        setLoginLoader,
        profileLoader,
        setProfileLoader,
        currentDepositBalance,
        setCurrentDepositBalance,
        currentWinningBalance,
        setCurrentWinningBalance,
        currentWalletBalance,
        setCurrentWalletBalance,
        ready,
        setReady,
        pincode,
        setPincode,
        showCount,
        setShowCount,
        deliveryAddress,
        setDeliveryAddress,
        paymentLoader,
        setPaymentLoader,
      }}
    >
      {children}
    </CountContext.Provider>
  );
};

export const useCount = () => useContext(CountContext);
