import React, { useState, useEffect } from "react";
import "./Terms.css";
import { TopNav } from "../../Components/TopNav/TopNav";
import TopnavWeb from "../../Components/TopNav/TopnavWeb";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

export const Terms = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {isMobile ? <TopNav title="Terms and Conditions" /> : <TopnavWeb />}
      <div className="conditions">
        <div className="head">
          <h6>Terms and Conditions</h6>
        </div>
        <p>
          "This document is an electronic record in terms of Information
          Technology Act,2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures. This document is published
          in accordance with the provisions of Rule 3 (1) of the Information
          Technology (Intermediaries guidelines) Rules, 2011 that require
          publishing the rules and regulations, privacy policy and Terms of Use
          for access or usage of BOCHEMART.COM Your use of BOCHEMART.COM is
          governed by the following terms and conditions (“Terms of Use”) as
          applicable including the applicable policies which are incorporated
          herein by way of reference. By registering, accessing BOCHEMART.COM,
          making use of , You shall be contracting with Patronymic technologies
          and industries private limited, these terms and conditions including
          the policies constitute your binding obligations. For this purpose,
          “You” shall mean any natural or legal person registering, browsing,
          accessing, BOCHEMART.COM For this purpose, “Third-Party or
          Third-Parties” shall mean any party other than Patronymic technologies
          and industries private limited The term “we”, “us”, “our” shall mean
          PATRONYMIC TECHNOLOGIES AND INDUSTRIES PRIVATE LIMITED . When You use
          any of the services provided through theYou shall be subject to the
          rules, guidelines, policies, terms, and conditions applicable to such
          service, and they shall be deemed to be incorporated into this Terms
          of Use and shall be considered as part and parcel of this Terms of
          Use. ACCESSING, BROWSING OR OTHERWISE USING BOCHEMART.COM INDICATE
          YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS, SO PLEASE READ THE
          TERMS AND CONDITIONS CAREFULLY BEFORE PROCEEDING operated by -
          PATRONYMIC TECHNOLOGIES AND INDUSTRIES PRIVATE LIMITED,
        </p>
        <div className="head">
          <h6>Cancellation of Order</h6>
        </div>
        <p>
          All orders placed through bochemart.com are processed with a very
          short turnaround time and hence cancellation of orders is not
          possible.
        </p>
        <div className="head">
          <h6>Return Policy</h6>
        </div>
        <p>
          Returns will be accepted only for quality concerns or damage of items
          in transit. Concerns should be raised".
          <a href="mailto:care@bochemart.com">care@bochemart.com</a>
          .within 48 hours of receipt with details of the order. In case of
          qualitative inferiority of the product, the total value of the product
          as well as courier charges borne by the customer will be refunded back
          to the customer's account. Products acknowledged as 'damaged' or
          'defective' will be replaced by us on receipt of the damaged product.
          Once we receive the item at our facility, we will verify its condition
          and initiate the process of refund/replacement accordingly.
        </p>
        <div className="head">
          <h6>Refund Policy</h6>
        </div>
        <p>
          <h5>General Terms</h5>
          <p>
            {" "}
            Once an order is placed and shipping has been carried out, refunds
            are generally not possible under normal circumstances. However, we
            are committed to ensuring customer satisfaction and will consider
            refund requests on a case-by-case basis.
          </p>{" "}
          <h5>Exceptions to the No Refund Policy</h5>{" "}
          <p>
            Refunds may be considered under the following circumstances: Damaged
            or Defective Products: If the product you received is damaged or
            defective, please contact us immediately. We will arrange for a
            replacement or a refund after verifying the issue. Incorrect
            Products: If you received a product that is different from what you
            ordered, please inform us as soon as possible. We will correct the
            error by sending the correct product or issuing a refund.
            Non-Receipt of Order: If you have not received your order within the
            estimated delivery time, please contact us. We will investigate the
            issue and provide a resolution, which may include a refund.
          </p>{" "}
          <h5>How to Request a Refund </h5>
          <p>
            Email Us: Please email us at{" "}
            <a href="mailto:care@bochemart.com">care@bochemart.com</a> with your
            order details, the reason for the refund request, and any supporting
            evidence (e.g., photos of damaged items).
          </p>{" "}
          <h5>Process and Timeline</h5>{" "}
          <p>
            Once we receive your refund request, our customer care team will
            review the details and get back to you within 2-3 business days. If
            your refund is approved, we will process it within 7-10 business
            days. The refund will be credited to your original method of
            payment.
          </p>{" "}
          <h5>Contact Information </h5>{" "}
          <p>
            For any queries or further assistance, please contact us via: Email:
            <a href="mailto:care@bochemart.com">care@bochemart.com</a>
          </p>
        </p>
        <div className="head">
          <h6>Terms of Use</h6>
        </div>
        <p>
          "You agree, undertake and confirm that your use of BOCHEMART.COM shall
          be strictly governed by the following binding principles: You hereby
          undertake and agree not to use BOCHEMART.COMfor (a) harassing or
          advocating harassment of another person; (b) transmission of junk
          mail”, chain letters”, or unsolicited mass mailing or spamming. (c)
          promoting illegal activities or conduct that is abusive, threatening,
          obscene, defamatory or libelous; (d) transmitting, sharing, uploading
          links of any software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource; or contains any trojan horses, worms, time
          bombs, cancelbots, easter eggs or other computer programming routines
          that may damage, detrimentally interfere with, diminish value of,
          surreptitiously intercept or expropriate any system, data or personal
          information Policy for Non- Hosting, Non- Storage, Non- Publication of
          Unlawful Information- Information Technology (Intermediary Guidelines
          and Digital Media Ethics Code) Amendment Rules, 2022.- Part II- Rule 3
          (a) (1) (b) – Due Diligence by Intermediary Patronymic technologies
          and industries private limited as an Intermediary herein informs You
          that it shall not host, display, upload, modify, publish, transmit,
          store, update or share any information that belongs to another person
          and to which the user does not have any right; is obscene,
          pornographic, paedophilic, invasive of another’s privacy, including
          bodily privacy, insulting or harassing on the basis of gender,
          libellous, racially or ethnically objectionable, relating or
          encouraging money laundering or gambling, or promoting enmity between
          different groups on the grounds of religion or caste with the intent
          to incite violence; is harmful to child; infringes any patent,
          trademark, copyright or other proprietary rights; deceives or misleads
          the addressee about the origin of the message or knowingly and
          intentionally communicates any misinformation or information which is
          patently false and untrue or misleading in nature; impersonates
          another person impersonates another person; threatens the unity,
          integrity, defence, security or sovereignty of India, friendly
          relations with foreign States, or public order, or causes incitement
          to the commission of any cognizable offence or prevents investigation
          of any offence or is insulting other nation; contains software virus
          or any other computer code, file or program designed to interrupt,
          destroy or limit the functionality of any computer resource; violates
          any law for the time being in force; Due Diligence Policy –
          Information Technology (Intermediary Guidelines and Digital Media
          Ethics Code) Amendment Rules, 2022.- Part II- Rule 3 (1) (a) (m)-
          Patronymic technologies and industries private limited. as an
          Intermediary shall take all reasonable measures to ensure
          accessibility of its services to users as part of due diligence
          protecting privacy, transparency. Further, Patronymic technologies and
          industries private limited. as an intermediary shall respect all the
          rights accorded to the citizens under the Constitution including
          Article 14, 19, 21 Policy for Non- Deployment of Technical
          Configuration of Computer Resource- Information Technology
          (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 –
          Due Diligence by Intermediary Rule 3(k)- Information Technology
          (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021
          Patronymic technologies and industries private limited. as an
          intermediary shall not knowingly deploy or install or modify technical
          configuration of its computer resource- BOCHEMART.COMor become party
          to act that may change or has the potential to change the normal
          course of operation of its computer resource – BOCHEMART.COMthan what
          it is supposed to perform thereby circumventing any law for the time
          being in force. Further, Patronymic technologies and industries
          private limited. may develop, produce, distribute or employ
          technological means for the purpose of performing the acts of securing
          its computer resource – BOCHEMART.COMand information contained
          therein; Policy for Voluntary Removal of Information or Disabling of
          Access- Rule 3 (d)- (Information Technology (Intermediary Guidelines
          and Digital Media Ethics Code) 2021– Due Diligence by Intermediary
          Patronymic technologies and industries private limited. shall remove
          or disable access to any information, data, communication link as
          specified in Policy for Non- Hosting, Non- Storage, Non- Publication
          of Unlawful Information- Information Technology (Intermediary
          Guidelines and Digital Media Ethics Code) Rules, 2021- Part II- Rule 3
          (1) (b) – Due Diligence by Intermediary on voluntary basis or on the
          basis of any grievances received thereof Policy for Reporting Cyber
          Security Incidents- Rule 3 (l) (Information Technology (Intermediary
          Guidelines and Digital Media Ethics Code) 2021– Due Diligence by
          Intermediary Patronymic technologies and industries private limited.
          shall report Cyber Security Incidents and share related information
          with the Indian Computer Response Team in accordance with the Policies
          and Procedures as mentioned in Information Technology (The Indian
          Computer Emergency Response Team and Manner of Performing Functions
          and Duties) Rules, 2013. Policy for Assistance to Government Agencies-
          Rule 3 (j) (Information Technology (Intermediary Guidelines and
          Digital Media Ethics Code) 2021– Due Diligence by Intermediary
          Patronymic technologies and industries private limited shall as soon
          as possible but not later than seventy-two hours of the receipt of an
          order, provide information under its control or possession, or
          assistance to the Government Agency which is lawfully authorised for
          investigative or protective or cyber securities activities, for the
          purposes of verification of identity, or for the prevention,
          detection, investigation, or prosecution, of offences under any law
          for the time being in force, or for cyber security incidents, however,
          such order shall be in writing clearly stating the purpose of seeking
          information or assistance, as the case may be,
        </p>
        <div className="head">
          <h6>Prohibited Use</h6>
        </div>
        <p>
          1. You shall not use any “deep-link”, “page-scrape”, “robot”, “spider”
          or other automatic device, program, algorithm or methodology, or any
          similar or equivalent manual process, to access, acquire, copy or
          monitor any portion of BOCHEMART.COM, Patronymic technologies and
          industries private limited. or any Content, or in any way reproduce or
          circumvent the navigational structure or presentation of
          BOCHEMART.COMor any Content, to obtain or attempt to obtain any
          materials, documents or information through any means not purposely
          made available through BOCHEMART.COM. We reserve our right to bar any
          such activity. 2. You shall not attempt to gain unauthorized access to
          any portion or feature of BOCHEMART.COMor any other systems or
          networks connected to BOCHEMART.COMor to any server, computer,
          network, or through BOCHEMART.COM by hacking, password “mining” or any
          other illegitimate means. 3. You shall not probe, scan or test the
          vulnerability of BOCHEMART.COMor any network connected to
          BOCHEMART.COMnor breach the security or authentication measures on
          BOCHEMART.COMor any network connected to BOCHEMART.COM. You may not
          reverse look-up, trace or seek to trace any information on any user of
          or visitor of BOCHEMART.COM or exploit BOCHEMART.COMor any information
          made available or offered by or through BOCHEMART.COMin any way where
          the purpose is to reveal any information, including but not limited to
          personal identification or information, other than your own
          information, as provided for by BOCHEMART.COM 4. You shall not make
          any negative, denigrating or defamatory statement(s) or comment(s)
          about us or the brand name, domain name used by us including the terms
          BOCHEMART.COM, Patronymic technologies and industries private limited.
          or otherwise engage in any conduct or action that might tarnish the
          image or reputation ofBOCHEMART.COM, Patronymic technologies and
          industries private limited.or otherwise tarnish or dilute any trade or
          service marks, trade name and/or goodwill associated with
          BOCHEMART.COM,Patronymic technologies and industries private limited
          owned or used by us. You agree that You will not take any action that
          imposes an unreasonable or disproportionately large load on the
          infrastructure of the BOCHEMART.COM’s systems or networks, or any
          systems or networks connected to BOCHEMART.COM 5. You agree not to use
          any device, software or routine to interfere or attempt to interfere
          with the proper working of BOCHEMART.COM 6. You may not forge headers
          or otherwise manipulate identifiers in order to disguise the origin of
          any message or transmittal, You send to us on or through
          BOCHEMART.COM. You may not pretend that You are, or that You
          represent, someone else, or impersonate any other individual or
          entity. 7. You may not use BOCHEMART.COMor any content for any purpose
          that is unlawful or prohibited by these Terms of Use, or to solicit
          the performance of any illegal activity or other activity which
          infringes the rights of Patronymic technologies and industries
          private limited. and / or others. 8. You shall at all times ensure
          full compliance with the applicable provisions of the Information
          Technology Act, 2000 and rules there under as applicable and as
          amended from time to time and also all applicable laws, rules and
          regulations. 9. You may not transmit any chain letters or unsolicited
          commercial or junk email via BOCHEMART.COM 10. It shall be a violation
          of these Terms of Use to use any information obtained from
          BOCHEMART.COMin order to harass, abuse, or harm another person, or in
          order to contact, advertise to, solicit, or sell to another person
          other than us without our prior explicit consent. In order to protect
          our users from such advertising or solicitation, we reserve the right
          to restrict your usage of BOCHEMART.COMfor a reasonable period which
          we deem appropriate in our sole discretion. 11. You understand that we
          have the right at all times to disclose any information (including the
          identity of the persons providing information through the form made
          available on BOCHEMART.COMas necessary to satisfy any law, regulation
          or valid governmental request. This may include, without limitation,
          disclosure of the information in connection with investigation of
          alleged illegal activity or solicitation of illegal activity or in
          response to a lawful court order or subpoena. In addition, we can (and
          You hereby expressly authorize us to) disclose any information about
          You to law enforcement or other government officials, as we, in our
          sole discretion, believe necessary or appropriate in connection with
          the investigation and/or resolution of possible crimes, especially
          those that may involve personal injury. 12. We reserve the right, but
          have no obligation, to monitor the materials posted on BOCHEMART.COM.
          13. It is possible that any users or third-parties (including
          unauthorized users or “hackers”) may post or transmit offensive or
          obscene materials on BOCHEMART.COMand that You may be involuntarily
          exposed to such offensive and obscene materials. We do not approve of
          such unauthorized use. 14. BOCHEMART.COM shall have all the rights to
          take necessary action and claim damages that may occur due to your
          involvement/participation in any way on your own or through group/s of
          people, intentionally or unintentionally in DoS/DDoS (Distributed
          Denial of Services). 15. You shall not reverse engineer
          BOCHEMART.COMin whatsoever manner 16. You shall not gain or attempt to
          gain unauthorized access in whatsoever manner to BOCHEMART.COM,
        </p>
        <div className="head">
          <h6>Contents Posted</h6>
        </div>
        <p>
          The Content, text, graphics, interfaces, visual interfaces,
          photographs, images, pictures, trademarks, logos, and artwork relating
          to BOCHEMART. COMshall be solely and exclusively owned by Patronymic
          technologies and industries private limited. BOCHEMART.COM may also
          contain description, images, content which is considered as
          third-party content. BOCHEMART.COM hereby acknowledges that such
          third-party content is owned by the respective owners.
        </p>
        <div className="head">
          <h6>License</h6>
        </div>
        <p>
          We hereby provide a limited, non-exclusive, non- transferable, non-
          sub-licensable, revocable license to use the platform in consideration
          of the terms and conditions and policies of BOCHEMART.COM. However, we
          shall reserve to terminate your license to use in case of any breach
          of these terms and conditions, policies of BOCHEMART.COM Nothing in
          this terms and conditions, policies ofBOCHEMART.COMshall be construed
          as granting any rights, title, interest with regard to the
          Intellectual Property relating to BOCHEMART.COM
        </p>

        <div className="head">
          <h6>General Obligations</h6>
        </div>
        <p>
          1. You shall be obliged to provide accurate information while using,
          signing up on BOCHEMART.COM 2. You shall also adhere to the minimum
          age requirement i.e., 18 years as defined by the law of the land 3.
          You shall read all the policies, terms mentioned in BOCHEMART.COM 4.
          You shall not engage in any activities mentioned in “PROHIBITED USE”
          (mentioned herein) either directly, indirectly or in whatsoever manner
        </p>

        <div className="head">
          <h6>Privacy</h6>
        </div>
        <p>
          We view protection of Your privacy as a very important principle. We
          understand clearly that You and Your Sensitive Personal Information is
          one of our most important assets. We store and process Your Sensitive
          Personal Information including any sensitive financial information
          collected (as defined under the Information Technology Act, 2000), if
          any, on computers that may be protected by physical as well as
          reasonable technological security measures and procedures in
          accordance with Information Technology Act 2000 and Rules there under.
          Our Privacy Policy is available for more details. By participating,
          you agree to let us use your photo and video for promotions. Winners'
          photos and videos will be featured on our social media.
        </p>

        <div className="head">
          <h6>Third-PartyAPI’s/ Links</h6>
        </div>
        <p>
          BOCHEMART.COM shall also be integrated with third-party API’s.
          However, in no event shall Patronymic technologies and industries
          private limited." " hold any responsibility, liability in this regard.
        </p>

        <div className="head">
          <h6>Legal Disclaimer</h6>
        </div>
        <p>
          BOCHEMART.COM and all content, included on or otherwise made available
          to You thereof are provided on “as is” and “as available” basis
          without any representation or warranties, express or implied except
          otherwise specified in writing. Without prejudice to the forgoing
          paragraph, Patronymic technologies and industries private limited.
          does not warrant that: - 1. COM shall be constantly available, or
          available at all; or the information thereof, is complete, true,
          accurate or non-misleading. 2. PATRONYMIC TECHNOLOGY does not warrant
          that the BOCHEMART.COM, information, Content, Images, Descriptions on
          or otherwise made available to You thereof, their servers; or
          electronic communication sent from us are free of viruses or other
          harmful components. Nothing on BOCHEMART.COMconstitutes, or is meant
          to constitute, advice of any kind. We disclaim any damages,
          liabilities, loss, claims including ANY SPECIAL, INCIDENTAL, INDIRECT
          or CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION OR ARISING OUT OF
          YOUR USE OFBOCHEMART.COM
        </p>

        <div className="head">
          <h6>Our Liability</h6>
        </div>
        <p>
          We shall be liable for any technical and relative issue with regard to
          BOCHEMART.COM. However, we shall hold no liability for unforeseen
          circumstances, force majeure events and situations beyond our control.
        </p>

        <div className="head">
          <h6>Third Party Liability</h6>
        </div>
        <p>
          The Third-Party shall be solely and exclusively liable for any and all
          issues arising out of third-party API’s, plugins, integrations
          connected to BOCHEMART.COM. In no event shall Patronymic technologies
          and industries private limited hold any liability for third-party
          issues or failure
        </p>
        <div className="head">
          <h6> Your Liability</h6>
        </div>
        <p>
          You shall be liable for all and all information provided by you upon
          while filling the details in the form made available on BOCHEMART.COM
          You shall be liable for any acts, actions, activities, commissions,
          omissions, misrepresentation, negligence arising out of your account
          or use of BOCHEMART.COM
        </p>
        <div className="head">
          <h6> Force Majeure</h6>
        </div>
        <p>
          In no event shall Patronymic technologies and industries
          private limited. be responsible for any failure to perform any
          services due to causes beyond its control, including but not limited
          to acts of God, war, and riot, acts of civil or military authorities,
          fire, floods or accident(s), pandemic & emergency situations etc.
        </p>

        <div className="head">
          <h6>Indemnity</h6>
        </div>
        <p>
          You shall indemnify and hold harmless Patronymic technologies and
          industries private limited., its directors, agents, and employees,
          from any claim or demand, or actions made by any third party or
          penalty imposed due to or arising out of Your breach of this Terms of
          Use, privacy Policy and other Policies, or Your violation of any law,
          rules or regulations or the rights (including infringement of
          intellectual property rights) of a third party.
        </p>

        <div className="head">
          <h6>Applicable Law / Jurisdiction</h6>
        </div>
        <p>
          Terms of Use shall be governed by and interpreted and construed in
          accordance with the laws of India. The place of jurisdiction shall be
          exclusively in(PLEASE MENTION THE PLACE), Kerala, India
        </p>

        <div className="head">
          <h6> Description</h6>
        </div>
        <p>
          Patronymic technologies and industries private limited does not
          warrant that description or other content made available thereof is
          accurate, complete, reliable, current, or error-free and assumes no
          liability in this regard
        </p>
        <div className="head">
          <h6> Limitation of Liability</h6>
        </div>
        <p>
          IN NO EVENT SHALL Patronymic technologies and industries
          private limited. BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR
          CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE TERMS OF
          USE, and EVEN IF CLIENT HAS BEEN INFORMED IN ADVANCE OF THE
          POSSIBILITY OF SUCH DAMAGES.
        </p>

        <div className="head">
          <h6> Shipping</h6>
        </div>
        <p>
          Orders received on working days before 4:30 p.m. shall be dispatched
          within 48 hours of the order being received. All orders received after
          4:30 p.m. shall be considered for dispatch on the next consecutive
          working day.Important note: Subject to product availability, shipping
          may take longer than expected.The Products are shipped through our
          courier partners. All products are shipped directly from our outlet in
          WAYANAD, WAYANAD being a hill station, you may experience a delay of
          one or two days compared to the normal services.
        </p>

        <div className="head">
          <h6> Charges</h6>
        </div>
        <p>
          The charge for shipping products shopped at bochemart.com depends on
          the location to which you want your purchases to be shipped. The
          Shipping charges for the Oils (Liquid Items) and Items sold in Glass
          bottles may vary as per the terms of our delivery partner.
        </p>

        <div className="head">
          <h6> Delivery</h6>
        </div>
        <p>
          The purchased products from bochemart.com will be shipped to the
          address provided .You can write to us in our customer email address
          helloboche@bobygroup.in, and our customer service phone number is
          +91-9961008008.Please ensure to verify the address entered by you to
          avoid incorrect delivery. If door to door delivery is not available by
          the courier. The courier would be sent to the courier branch nearest
          to PIN provided. In case of multiple orders, items may be shipped
          under single purchase orders. This is to facilitate quicker and safer
          shipping of various items. Your order dispatch time will be between
          10.00 am to 6.00 pm unless otherwise specified. Patronymic
          technologies and industries private limited is not responsible for
          delayed shipments in the event of service interruptions or failures
          caused by events, which are beyond the control of Patronymic
          technologies and industries private limited. These interruptions
          include but are not limited to transportation system, customs
          clearance issues, shipping carrier delays or cardholder's credit card
          or bank delays or acts of God.
        </p>

        <div className="head">
          <h6> Time Frame</h6>
        </div>
        <p>
          All delivery dates posted online and offline are estimated lead times.
          Estimated lead times and delivery times are always subject to change
          without notification. Seller cannot be held responsible for any delay
          in delivery or extended lead times.
        </p>

        <div className="head">
          <h6> Shipping Charges</h6>
        </div>
        <p>
          Shipping costs are based on weight and are calculated at the time of
          selecting the item. To reflect the policies of the courier companies
          we use, all weights will be rounded up to the next full kg.Order
          deliveries will be made by a third-party courier and logistics company
          Speed & Safe usually between 10.30am - 7:00pm from Monday to Saturday.
          BOCHEMART.COM may include inaccuracies and typographical errors.
          Changes and improvements are periodically made to BOCHEMART.COM.
        </p>

        <Link to="/home" className="link">
          <button>Ok</button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};
